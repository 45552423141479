// focus check
// *:focus {
//   outline: 10px solid pink !important;
// }
:root {
  --vh: 1vh;
  --primary-color: #ffd707;
  --primary-color2: #055db5;
  --bg-color: #273043;
  --bg-color-blue01: #cccccc;
  --bg-color-blue02: #eeeeee;
  --bg-color-gray: #e5e9f2;
  --bg-color-green: #20a849;
  //
  --item-margin-x: 0.4rem;
  --item-margin-y: 0.6rem;

  --white-color: #fff;
  --base-txt-color: #202020;
  --strong-txt-color: #42444e;
  --sub-txt-color: #666666;
  --sub-txt-color2: #999999;

  --alert-txt-color: #ff1c37;
  --placeholder-txt-color: #b9c1c9;

  --line-color01: #cccccc;
  --line-color02: #eeeeee;
  --line-color03: #e5e9f2;

  --disabled-color: #b0b0b0;
  --disabled-bg-color: #f6f6f6;

  --input-disabled-color: #c2c2c2;
  --input-disabled-bg-color: #f2f4f6;

  --base-padding: 2.4rem;
  --outer-padding: -2.4rem;

  --loading-svg-offset: 187;
  --loading-svg-duration: 1.4s;
}
html {
  height: 100%;
  font-size: 62.5%;
  text-size-adjust: none;
}
body {
  height: 100%;
  font: normal 1.6rem/2.4rem 'Pretendard', sans-serif;
  color: var(--base-txt-color);
  letter-spacing: -0.02em;
  word-break: keep-all;
  -webkit-font-smoothing: antialiased;
  // html.is-app & {
  //   overscroll-behavior-y: contain; // 당겨서 새로고침 막기
  // }
}
#root {
  height: 100%;
}
.market {
  min-width: 1680px;
  &.fo {
    min-width: 1280px;
  }
}
html.mobile {
  a,
  button,
  select,
  input,
  textarea,
  iframe,
  [tabindex] {
    outline: none;
  }
}
html.pc {
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.1);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    box-shadow: inset 0 0 0.4rem rgba(0, 0, 0, 0.2);
    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: rgba(0, 0, 0, 0);
  }
}

/*color*/
.fc-primary {
  color: var(--primary-color) !important;
}
.fc-primary2 {
  color: var(--primary-color2) !important;
}
.fc-red {
  color: var(--alert-txt-color) !important;
}
.fc-up {
  // color: #eb5757 !important;
  color: #e45055 !important;
}
.fc-grey {
  color: var(--sub-txt-color) !important;
}
.fc-grey2 {
  color: var(--sub-txt-color2) !important;
}
.fc-down {
  color: #447eee !important;
}
.fc-white,
.fc-fff {
  color: #fff !important;
}
.fc-black,
.fc-212121 {
  color: #212121 !important;
}
.fc-424242 {
  color: #424242 !important;
}
.fc-base,
.fc-606060 {
  color: #606060 !important;
}
.fc-818892 {
  color: #818892 !important;
}
.fc-979797 {
  color: #979797 !important;
}
.fc-b0b0b0 {
  color: #b0b0b0 !important;
}
.fc-15b071 {
  color: #15b071 !important;
}
.fc-23adfa {
  color: #23adfa !important;
}
.fc-b9c1c9 {
  color: #b9c1c9 !important;
}
.fc-25b69c {
  color: #25b69c !important;
}
.fc-e94187 {
  color: #e94187 !important;
}
.fc-e45055 {
  color: #e45055 !important;
}
.fc-447eee {
  color: #447eee !important;
}
.fc-fd7e64 {
  color: #fd7e64 !important;
}

/* 폰트 사이즈 */
@for $i from 0 through 70 {
  .fz-#{$i} {
    font-size: $i * 0.1rem !important;
  }
  .lh-#{$i} {
    line-height: $i * 0.1rem !important;
  }
}

/* 굵기 */
.fw-bold {
  font-weight: 700 !important;
}
.fw-smbold {
  font-weight: 600 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-normal,
.fw-regular {
  font-weight: 400 !important;
}

/* 정렬 */
.t-right {
  text-align: right !important;
}
.t-left {
  text-align: left !important;
}
.t-center {
  text-align: center !important;
}
.va-top {
  vertical-align: top !important;
}
.va-middle {
  vertical-align: middle !important;
}
.va-bottom {
  vertical-align: bottom !important;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.clear {
  clear: both;
}

.flex-end {
  display: flex;
  justify-content: end;
}
/* flex */
.inline-flex {
  display: inline-flex !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-center {
  align-items: center !important;
}
.align-end {
  align-items: flex-end !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.space-between {
  justify-content: space-between !important;
}
.space-around {
  justify-content: space-around !important;
}
.space-evenly {
  justify-content: space-evenly !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.justify-self-start {
  justify-self: flex-start !important;
}
.justify-self-center {
  justify-self: center !important;
}
.justify-self-end {
  justify-self: flex-end !important;
}
.justify-stretch {
  justify-items: stretch !important;
}
.flex-11 {
  flex: 1 1 auto !important;
}
.flex-10 {
  flex: 1 0 auto !important;
}
.flex-01 {
  flex: 0 1 auto !important;
}
.flex-00 {
  flex: 0 0 auto !important;
}
.flex-wrap {
  flex-flow: wrap !important;
}
.flex-column {
  flex-direction: column !important;
  &.flex > div + div {
    margin-left: 0;
    margin-top: var(--item-margin-y);
  }
}
.flex {
  display: flex;
  & + :is(.flex, .input, .select, .textarea) {
    margin-top: var(--item-margin-y);
  }
  > :is(.input, .select, .textarea) + :is(.input, .select, .textarea) {
    margin-top: 0;
  }
  > * + * {
    margin-left: var(--item-margin-x);
  }
  &.full {
    > :is(.input, .select, .textarea) + :is(.input, .select, .textarea) {
      margin-top: 0;
    }
    > *:not(.unit):not(i) {
      width: 100%;
    }
    @include class-begins('w-', '>') {
      flex-grow: 0;
      flex-shrink: 0;
    }

    &.no-gap {
      --item-margin-x: 0;
    }
  }

  > .unit {
    display: flex;
    width: auto;
    align-items: center;
    font-size: 1.5rem;
    padding: 0 0.2rem;
  }

  > .button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    // flex: 1 1 auto;
    // width: auto !important;
  }
}

@for $i from 0 through 600 {
  .gap-#{$i} {
    gap: $i * 0.1rem !important;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-x-hidden {
  overflow-x: hidden !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-y-hidden {
  overflow-y: hidden !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}

// div:table
.tbl {
  display: table;
  width: 100%;
  .th,
  .td {
    display: table-cell;
  }
}

// width
.w-auto {
  width: auto !important;
}
.w-100p {
  width: 100% !important;
}
@for $i from 0 through 1000 {
  .w-#{$i} {
    width: $i * 0.1rem !important;
  }
  .w-#{$i}f {
    flex: 0 0 $i * 0.1rem !important;
  }
}
// @for $i from 0 through 100 {
//   @if $i !=33 and $i !=66 {
//     .w-#{$i}p {
//       width: $i * 1% !important;
//     }
//     .w-#{$i}vw {
//       width: $i * 1vw !important;
//     }
//     .w-#{$i}fp {
//       flex: 0 0 $i * 1% !important;
//     }
//   }
// }
.w-33p {
  width: 33.3333% !important;
}
.w-66p {
  width: 66.6666% !important;
}
.w-33vw {
  width: 33.3333vw !important;
}
.w-66vw {
  width: 66.6666vw !important;
}
.w-33fp {
  flex: 0 0 33.3333% !important;
}
.w-66fp {
  flex: 0 0 66.6666% !important;
}

// height
.h-auto {
  height: auto !important;
}
@for $i from 0 through 100 {
  .h-#{$i} {
    height: $i * 0.1rem !important;
  }
}
@for $i from 1 through 40 {
  .h-#{$i*5 + 100} {
    height: $i * 0.5rem + 10rem !important;
  }
}
@for $i from 0 through 100 {
  @if $i !=33 and $i !=66 {
    .h-#{$i}p {
      height: $i * 1% !important;
    }
    .h-#{$i}vh {
      height: -vh(#{$i}) !important;
    }
  }
}
.h-33p {
  height: 33.3333% !important;
}
.h-66p {
  height: 66.6666% !important;
}
.h-33vh {
  height: -vh(33.3333) !important;
}
.h-66vh {
  height: -vh(66.6666) !important;
}

// margin
.mg-auto {
  margin: auto !important;
}
.mg-auto-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mg-auto-y {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
@for $i from 0 through 100 {
  .mg-#{$i} {
    margin: $i * 0.1rem !important;
  }
  .mg-#{$i}-x {
    margin-left: $i * 0.1rem !important;
    margin-right: $i * 0.1rem !important;
  }
  .mg-#{$i}-y {
    margin-top: $i * 0.1rem !important;
    margin-bottom: $i * 0.1rem !important;
  }
  .mt-#{$i} {
    margin-top: $i * 0.1rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i * 0.1rem !important;
  }
  .ml-#{$i} {
    margin-left: $i * 0.1rem !important;
  }
  .mr-#{$i} {
    margin-right: $i * 0.1rem !important;
  }
}
// @for $i from 1 through 20 {
//   .mg-#{$i*5 + 100} {
//     margin: $i * 0.5rem + 10rem !important;
//   }
//   .mg-#{$i*5 + 100}-x {
//     margin-left: $i * 0.5rem + 10rem !important;
//     margin-right: $i * 0.5rem + 10rem !important;
//   }
//   .mg-#{$i*5 + 100}-y {
//     margin-top: $i * 0.5rem + 10rem !important;
//     margin-bottom: $i * 0.5rem + 10rem !important;
//   }
//   .mt-#{$i*5 + 100} {
//     margin-top: $i * 0.5rem + 10rem !important;
//   }
//   .mb-#{$i*5 + 100} {
//     margin-bottom: $i * 0.5rem + 10rem !important;
//   }
//   .ml-#{$i*5 + 100} {
//     margin-left: $i * 0.5rem + 10rem !important;
//   }
//   .mr-#{$i*5 + 100} {
//     margin-right: $i * 0.5rem + 10rem !important;
//   }
// }
@for $i from 1 through 30 {
  .mt-m#{$i} {
    margin-top: -$i * 0.1rem !important;
  }
  .mb-m#{$i} {
    margin-bottom: -$i * 0.1rem !important;
  }
  .ml-m#{$i} {
    margin-left: -$i * 0.1rem !important;
  }
  .mr-m#{$i} {
    margin-right: -$i * 0.1rem !important;
  }
}

// padding
@for $i from 0 through 100 {
  .pd-#{$i} {
    padding: $i * 0.1rem !important;
  }
  .pd-#{$i}-x {
    padding-left: $i * 0.1rem !important;
    padding-right: $i * 0.1rem !important;
  }
  .pd-#{$i}-y {
    padding-top: $i * 0.1rem !important;
    padding-bottom: $i * 0.1rem !important;
  }
  .pt-#{$i} {
    padding-top: $i * 0.1rem !important;
  }
  .pb-#{$i} {
    padding-bottom: $i * 0.1rem !important;
  }
  .pl-#{$i} {
    padding-left: $i * 0.1rem !important;
  }
  .pr-#{$i} {
    padding-right: $i * 0.1rem !important;
  }
}
// @for $i from 1 through 20 {
//   .pd-#{$i*5 + 100} {
//     padding: $i * 0.5rem + 10rem !important;
//   }
//   .pd-#{$i*5 + 100}-x {
//     padding-left: $i * 0.5rem + 10rem !important;
//     padding-right: $i * 0.5rem + 10rem !important;
//   }
//   .pd-#{$i*5 + 100}-y {
//     padding-top: $i * 0.5rem + 10rem !important;
//     padding-bottom: $i * 0.5rem + 10rem !important;
//   }
//   .pt-#{$i*5 + 100} {
//     padding-top: $i * 0.5rem + 10rem !important;
//   }
//   .pb-#{$i*5 + 100} {
//     padding-bottom: $i * 0.5rem + 10rem !important;
//   }
//   .pl-#{$i*5 + 100} {
//     padding-left: $i * 0.5rem + 10rem !important;
//   }
//   .pr-#{$i*5 + 100} {
//     padding-right: $i * 0.5rem + 10rem !important;
//   }
// }
.no-pd {
  padding: 0 !important;
}

// 글자 말줄임
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
// $i = 몇 줄까지 노출시키는지, 2 = 2줄까지만 노출
@for $i from 1 through 5 {
  .ellipsis-#{$i} {
    @include ellipsis($i);
  }
}

// border
.bd-none {
  border: none !important;
}
.bd-l-none {
  border-left: none !important;
}
.bd-r-none {
  border-right: none !important;
}
.bd-t-none {
  border-top: none !important;
}
.bd-b-none {
  border-bottom: none !important;
}

// @for $i from 0 through 10 {
//   .bd-#{$i} {
//     border-width: $i * 0.1rem !important;
//   }
//   .bd-l-#{$i} {
//     border-left-width: $i * 0.1rem !important;
//   }
//   .bd-r-#{$i} {
//     border-right-width: $i * 0.1rem !important;
//   }
//   .bd-t-#{$i} {
//     border-top-width: $i * 0.1rem !important;
//   }
//   .bd-b-#{$i} {
//     border-bottom-width: $i * 0.1rem !important;
//   }
// }

// @for $i from 0 through 30 {
//   .bd-radius-#{$i} {
//     border-radius: $i * 0.1rem !important;
//   }
// }

// opacity
// @for $i from 1 through 20 {
//   .op-#{$i*5} {
//     opacity: $i * 0.05 !important;
//   }
// }

// filter
.blur img {
  filter: blur(6px);
}
.grayscale img {
  filter: grayscale(1);
}

// etc
.lottie {
  svg {
    vertical-align: top;
  }
}
.scroll-x {
  @include scroll-x;
  .section & {
    @include outer;
    @include scroll-padding;
  }
}
.scroll-y {
  @include scroll-y;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.hide {
  display: none !important;
  &.show {
    display: block !important;
  }
}
.blind {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  @include text-blind;
}
.break-all {
  word-break: break-all;
}
.keep-all {
  word-break: keep-all;
}
.line-through {
  text-decoration: line-through;
}
.line-underline {
  text-decoration: underline;
}
.nowrap {
  white-space: nowrap;
}
.underline {
  .fc-red {
    text-decoration: underline;
  }
  text-decoration: underline;
}
.pre-line {
  white-space: pre-line;
}
.pre-line-wrap {
  display: block;
  white-space: pre-line;
}
.dummy {
  background: #ccc;
  padding: 4em 1em;
  text-align: center;
  font-size: 2.2rem;
}
.console {
  position: fixed;
  padding: 1rem;
  line-height: 2;
  font-size: 1.5rem;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  background: rgba(255, 0, 0, 0.7);
  z-index: 1000;
  word-break: break-all;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}

.hr-line {
  display: block;
  margin: 0;
  height: 0;
  border: 0 none;
  background: #f2f4f6;
  height: 0.1rem;
  &.thick {
    height: 0.8rem;
  }
  &.ty2 {
    background: #ebebeb;
  }
  .section &:not(.inset) {
    @include outer;
  }
}

html:not(.ios):not(.safari) {
  .ios-img {
    display: none;
  }
  .ios-content {
    display: none;
  }
}
html.ios,
html.safari {
  .android-img {
    display: none;
  }
  .android-content {
    display: none;
  }
}

//app
html.is-app {
  // -webkit-touch-callout: none;
  @include is-native;
  // a {
  //   @include is-native;
  // }
}

.select {
  border: none;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.h-line {
  height: 1px;
  margin: 4rem -4.8rem;
  border-color: var(--line-color02);
  &.bold {
    margin: 1.6rem 0 3.2rem;
    border-color: var(--line-color01);
  }
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}
.pagination li {
  margin: 0 5px;
  cursor: pointer;
}
.pagination li.active {
  font-weight: bold;
}
.guide-bg {
  position: relative;
  height: 1000px;
  margin-top: 2rem;
  padding: 4rem;
  background-color: #f3f3f3;
  border-radius: 1rem;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4rem;
    background-color: #f3f3f3;
  }
}

.input {
  .w-100 {
    width: 100px;
  }

  .w-300 {
    width: 300px;
  }
}

// 백그라운드 스크롤 막음
.scroll-locked {
  overflow: hidden;
}

.se-component.se-image-container figure {
  display: block;
  margin: 0 auto;
}

.sun-editor-editable[contenteditable=true] .se-component {
  outline: none !important;
}

.sun-editor {
  height: 70%;
  z-index: 100;
}

.se-resize-dot {
  display: none !important;
}

.se-controller .se-resizing-container {
  z-index: 1;
}