.card {
  width: 100%;
  margin-top: 2rem;

  .card-button {
    display: flex;
    justify-content: end;
  }
  .card-row {
    display: flex;
    align-items: flex-start;
    gap: 3rem;
    padding: 32px;
    border-radius: 8px;
    background-color: #fff;
    margin-top: 2rem;

    .img-container {
      img {
        width: 25rem;
        height: 25rem;
      }
    }

    .info-container {
      display: flex;
      width: 100%;
      gap: 2rem;
      flex-direction: column;

      .info-head-wrapper {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        width: 100%;

          .title {
            font-size: 1.7rem;
            font-weight: 700;
          }

          .date {
            font-size: 1.4rem;
            font-weight: 500;
            color: var(--sub-txt-color);
          }
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        
        .title {
          font-size: 1.7rem;
          font-weight: 700;
          }

        .info {
          display: flex;
          flex-direction: column;
          gap: 3px;

          img {
            width: 24px;
            height: 24px;
          }
          .input-box {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          span {
            color: var(--strong-txt-color);
            font-size: 1.5rem;
            font-weight: 600;
          }

          p {
            font-size: 1.4rem;
            font-weight: 700;
          }
        }
      }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .info {
          display: flex;
          flex-direction: column;
          gap: 3px;

          span {
            font-size: 1.5rem;
          }

          p {
            font-size: 1.4rem;
            font-weight: 500;
          }

          img {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

  }

}


.product-detail {
    padding: 8px 0px 48px;
    height: 100%;
    max-width: 1440px;

    span {
        text-decoration: none;
        height: fit-content;
        z-index: 0;
        pointer-events: none;
        display: inline-block;
        color: black;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.5;
        user-select: auto;
      }

    .detail-card {
        margin-top: 2rem;
        -webkit-box-pack: start;
        justify-content: flex-start;
        display: flex;
        width: 100%;
        height: 100%;


        .card-wrapper {
          display: flex;
          width: 100%;
          height: 100%;


          .editor-wrapper {
              display: flex;
              width: 100%;
          
              .images-wrap {
                  min-width: 150px;
                    max-width: 150px;
                    max-height: 750px;
                    /* 최대 높이 */
                    height: 100%;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    padding: 16px 0px 0px 16px;
                    width: 100%;
                    box-sizing: border-box;
                    overflow-y: auto;
                    /* 내부 스크롤 */
                    background-color: #fff;
                    border-right: 1px solid var(--line-color01);
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 15px;
                    position: relative;
              
                  .refined-image {
                    background-color: #fff;
                    min-height: 104px;
                    min-width: 104px;
                    z-index: 8;
                    position: relative;
                    /* image-delete의 기준 컨텍스트 */
                    box-sizing: border-box;
                    cursor: pointer;
              
                    .image-area {
                      background-color: #fff;
                      min-height: 104px;
                      min-width: 104px;
                      display: flex;
                      position: relative;
                      align-items: center;
                      justify-content: center;
                      box-sizing: border-box;
                      border-radius: 8px;
                      outline: 1px solid var(--line-color01);
                      overflow: hidden;
              
                      img {
                        max-width: 104px;
                        max-height: 104px;
                        margin: auto;
                      }
              
                     
                    }

                     .image-delete {
                        position: absolute;
                        /* 부모(.image-area) 기준으로 위치 설정 */
                        right: -6px;
                        top: -6px;
                        width: 18px;
                        height: 18px;
                        background-color: var(--line-color02);
                        border-radius: 9px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 10;
                        /* 다른 요소 위로 배치 */
              
                        button {
                          width: 100%;
                          height: 100%;
                          border: none;
                          background: transparent;
                          cursor: pointer;
                        }
                      }
                  }
                }
              
                .editor-wrap {
                  width: 100%;
                  max-width: 1180px;
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                  background-color: #fff;
                }
              }

          .detail-option-wrapper {
            // 기능 완료되면 주석해제
                position: sticky;
                top: 10rem;
                /* 스크롤 시 상단에서의 간격 */
                margin-left: 8px;
                box-sizing: border-box;
                padding: 16px 0px;
                background-color: #fff;
                min-width: 160px;
                height: fit-content;
                border-radius: 8px;

            

              .dash {
                  margin: 1rem 1rem 0 1rem;
                  padding-top: 1rem;
                  border-top: 1px solid var(--line-color01);
              }

              .opacity {
                  opacity: 0.5;
              }

              .option-wrapper {
                  padding: 0px 12px;
                  display: flex;
                  gap: 8px;
                  flex-direction: column;
                  -webkit-box-pack: justify;
                  justify-content: space-between;
                  width: 100%;

                  .question-mark {
                      width: 7.6rem;
                      background-image: url(/images/common/question-mark.png);
                    }



                .arrow {
                  display: flex;
                  justify-content: center;
                }

                  .option-wrap {
                      width: 100%;
                      display: flex;
                      -webkit-box-pack: justify;
                      justify-content: space-between;
                      -webkit-box-align: center;
                      align-items: center;
                      padding: 3px 0px;

                      .option-title {
                          display: flex;
                          -webkit-box-pack: center;
                          justify-content: center;
                          -webkit-box-align: center;
                          align-items: center;
                          gap: 2px;
                      }

                  }

                  .translate-wrap {
                    display: flex;
                    
 
                  }
              }
          }
        }
    }
}

// 에디터 사이즈 조절바 삭제
.se-resizing-bar {
  display: none !important;
}

