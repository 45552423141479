.app__layout {
  .paper--wrapper {
    width: 100px;
    height: 100px;
    &__primary {
      background-color: $primary;
    }
  }
}

.detail-title {
  background-color: #86bcdb;
}
.detail-content {
  background-color: $Navy-100;
}

.temp-pw-field {
  .MuiFormHelperText-root {
    color: #783abc !important;
    font-weight: 600;
  }
}

.org-menu-btn {
  position: absolute !important;
  right: 10px;
  top: 8px;
  z-index: 1;
}

.over-visible {
  button {
    visibility: hidden;
  }
  &:hover {
    button {
      visibility: visible;
    }
  }
}


// tiny editor css
.tox .tox-edit-area::before {
  border: none !important;
  border-radius: none;
}

.tox-toolbar__primary {
  button {
    border: none;
  }
}