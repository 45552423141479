$white: #ffffff;
$black: #000000;

// Navy
$Navy-900: #050f28;
$Navy-800: #08132e;
$Navy-700: #1e2c4a;
$Navy-600: #3f5480;
$Navy-500: #4a6a8e;
$Navy-400: #467da5;
$Navy-300: #86bcdb;
$Navy-200: #c1deee;
$Navy-100: #e4e8f1;

// Green
$Green-900: #002430;
$Green-800: #004a46;
$Green-700: #006d5a;
$Green-600: #078362;
$Green-500: #0ca777;
$Green-400: #40cc99;
$Green-300: #b7e5d4;
$Green-200: #daf0eb;
$Green-100: #f2fbf7;

// Yellow
$Yellow-900: #74550b;
$Yellow-800: #a4770a;
$Yellow-700: #bb8800;
$Yellow-600: #cfa237;
$Yellow-500: #ebb217;
$Yellow-400: #ffbb00;
$Yellow-300: #ffcc44;
$Yellow-200: #ffde31;
$Yellow-100: #ffe5a1;

// Red
$Red-900: #8f0000;
$Red-800: #b10020;
$Red-700: #be123b;
$Red-600: #d32053;
$Red-500: #e4194a;
$Red-400: #f9346c;
$Red-300: #ff6688;
$Red-200: #ff88a0;
$Red-100: #ffc9d4;

// Gray
$Gray-900: #181a1f;
$Gray-800: #23252b;
$Gray-700: #292c33;
$Gray-600: #434852;
$Gray-500: #666b76;
$Gray-400: #858992;
$Gray-300: #adaeb9;
$Gray-200: #d6d8de;
$Gray-100: #f2f4f5;
$Gray-50: #fbfcfe;

// Blue
$Blue-900: #182658;
$Blue-800: #142666;
$Blue-700: #0f387d;
$Blue-600: #144faf;
$Blue-500: #146aff;
$Blue-400: #4488ff;
$Blue-300: #77aaff;
$Blue-200: #afcdff;
$Blue-100: #d1e2ff;

// Cyan
$Cyan-900: #073d3d;
$Cyan-800: #1f5252;
$Cyan-700: #1d6464;
$Cyan-600: #227777;
$Cyan-500: #188299;
$Cyan-400: #0799cf;
$Cyan-300: #00bbee;
$Cyan-200: #90e1ff;
$Cyan-100: #c9f0ff;
