.collect-label {
  width: 100%;
  height: 100%;
  margin-right: 0.5rem;
  padding: 0.2rem 1rem;
  border-radius: 1.2rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2rem;

  &.method {
    border: 1px solid #dfe6f0;
    background-color: #ffffff;
    color: #6b6b6b;
  }

  &.count {
    background-color: #f1f1f1e1;
    color: #5f5f5fad;

    &.success {
      background-color: #cbecd0e1;
      color: #245032e0;
    }

    &.fail {
      background-color: #f7dedde1;
      color: #914040e0;
    }
  }
}

.title {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
}