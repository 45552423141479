@import '../../../assets/styles/palette';

.button {
  display: inline-block;
  flex-shrink: 0;
  height: 4rem;
  // margin-right: 0.4rem;
  padding: 0.8rem 1.6rem;
  border-radius: 0.6rem;
  font-size: 1.5rem;
  font-weight: 600;
  i {
    margin-right: 0;
  }
  &:disabled {
    opacity: 0.4;
    &:hover {
      box-shadow: none;
    }
  }
   
  &:hover {
    box-shadow: 0px 4px 4px 0px #0000001f;
  }
  &.blue-line {
    border: 1px solid var(--primary-color2);
    color: var(--primary-color2);
    &.download {
      padding-left: 3.2rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='%23055DB5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        left 1.2rem center / 2rem 2rem no-repeat;
      &:disabled {
        background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='%23055DB5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          left 1.2rem center / 2rem 2rem no-repeat;
      }
    }
  }

   

  &.line {
    border: 1px solid transparent;
    &.download {
      border: 1px solid var(--line-color02);
      &::before,
      &::after {
        content: none;
      }
    }
    span {
      opacity: 0;
    }
    position: relative;
    z-index: 0;
    border: none;
    outline: none;
    color: var(--strong-txt-color);
    &::before,
    &::after {
      content: '';
      position: absolute;
    }
    &::before {
      z-index: -1;
      border-radius: 0.5rem;
      top: 1px;
      right: 0;
      bottom: 1px;
      left: 0;
      background-color: var(--white-color);
    }
    &::after {
      z-index: -2;
      border-radius: 0.6rem;
      top: 0;
      right: -1px;
      bottom: 0;
      left: -1px;
      background: linear-gradient(180deg, #aeb6d0 76.39%, #939ab3 100%);
    }
    &:hover,
    &.mouseover {
      &::after {
        background: var(--strong-txt-color);
      }
    }
    &:disabled {
      opacity: 1;
      background-color: var(--disabled-bg-color);
      border: 1px solid var(--line-color02);
      color: var(--sub-txt-color2);
      &::before,
      &::after {
        content: none;
      }
    }
  }

  &.download {
    line-height: 1 !important;
    background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      left 1.2rem center / 2rem 2rem no-repeat;
    &:disabled {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='%23CCCCCC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
    &.small {
      padding-left: 3.2rem;
    }
  }
  &.bg-primary {
    background-color: var(--primary-color2);
    color: var(--white-color);
    &:hover,
    &.mouseover {
      background-color: #064f98;
    }
    &:disabled:hover {
      background-color: var(--primary-color2);
    }
  }
  &.bo-primary {
    background-color: #ff602b;
    color: var(--white-color);
  }
  &.bg-dark {
    background-color: var(--strong-txt-color);
    color: var(--white-color);
    &:hover,
    &.mouseover {
      background-color: var(--base-txt-color);
    }
    &:disabled:hover {
      background-color: var(--strong-txt-color);
    }
  }
  &.bg-green {
    background-color: var(--bg-color-green);
    color: var(--white-color);
  }
  &.largest {
    height: 5.6rem;
    padding: 1.2rem 1.4rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-align: center;
  }
  &.filter {
    min-width: 5.8rem;
    height: 3.2rem;
    padding: 0.6rem 1.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    line-height: 2rem;
    border: 1px solid #e4e4e7;
    background-color: $Gray-50;
    color: #6b6b6b;

    span {
      font-size: 1.6rem;
      font-weight: 600;
    }
    .all {
      color: var(--sub-txt-color);
    }
    .filter {
      color: var(--strong-txt-color);
    }
    .fail {
      color: $Red-400;
    }
    .success {
      
      color: $Blue-500;
    }
    &:hover {
        box-shadow: none;
        background-color: $Gray-100;
    }
  }
  &.small {
    min-width: 5.8rem;
    height: 3.2rem;
    padding: 0.6rem 1.6rem;
    font-size: 1.4rem;
    text-align: center;
    line-height: 2rem;
  }
  &.float-tl {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: 0.6rem 1.2rem 0.6rem 0.8rem;
  }

  &.round {
    border-radius: 1.6rem;
    font-weight: 500;

    &.outline {
      background-color: var(--white-color);
      color: var(--strong-txt-color);
      border: 1px solid var(--line-color01);
    }

    &.on {
      background-color: var(--strong-txt-color);
      color: var(--white-color);
    }
    &.off {
      background-color: var(--disabled-bg-color);
      border: 1px solid var(--line-color01);
      color: var(--sub-txt-color2);
    }
    &:hover {
      box-shadow: none;
    }
  }
  &.large {
    height: 4.8rem;
    padding: 1.2rem 1.6rem;
    text-align: center;
  }
  &.mini {
    height: 2.4rem;
    padding: 0 1rem;
    font-size: 1.2rem;
    // line-height: 1.6rem;
    font-weight: 400;
    &.line.download {
      padding-left: 2.2rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
        left 0.6rem center / 1.6rem 1.6rem no-repeat;
      &:disabled {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 16.6666H15M10 3.33325V13.3333M10 13.3333L12.9167 10.4166M10 13.3333L7.08333 10.4166' stroke='%23CCCCCC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
    &.line.ty2 {
      border: 1px solid var(--line-color01);
      color: var(--sub-txt-color);
      &:after {
        content: none;
      }
    }
  }

  &.file {
    input {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
      &::file-selector-button {
        display: none;
      }
    }
    label {
      line-height: 2rem;
    }
  }
  &.check {
    position: relative;
    height: 1.9rem;
    padding: 0.3rem 0.4rem 0.3rem 0.6rem;
    font-size: 1.1rem;
    line-height: 1.1rem;
    font-weight: 400;
    border: 1px solid var(--line-color01);
    border-radius: 3px;
    &::after {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-left: 0.2rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='8' height='9' viewBox='0 0 8 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 2.5L5 4.5L3 6.5' stroke='%23666666' stroke-width='0.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  &.btn-chat {
    color: var(--white-color);
    background-color: var(--bg-color-green);
  }
}