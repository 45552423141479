.switch-container {
  display: flex;
  align-items: center;
  gap: 0.6rem;

  input {
    display: none;
  }

  span {
    font-size: 1.2rem;
    color: #A1A6B1;
    line-height: 1;
    transform: translate3d(0, 0.5px, 0);

    &.checked {
      color: #626975;
    }
  }

  .switch-box {
    width: 36px;
    padding: 3px;
    border-radius: 999px;
    background-color: #E6E8EC;

    &:has(input[type='checkbox']:checked) {
      background-color: var(--primary-color2);
      
        label {
          transform: translate3d(14px, 0, 0);
        }
    }
  }

  .switch-circle {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transition: 0.2s ease;
      box-shadow: 0 4px 4px 0 rgba(82, 86, 92, 0.05);
      cursor: pointer;

  }
}