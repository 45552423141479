.header-space {
  position: sticky;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  top: 0;
  height: 7rem;
  padding-left: 3.2rem;
  background-color: var(--primary-color2);
  color: var(--white-color);
  &.mo {
    align-items: center;
    min-width: 36rem;
    height: 6.4rem;
    padding: 0 1.6rem;
    .mo-lnb {
      width: 3.2rem;
      height: 3.2rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.33594 7.6665C5.33594 7.11422 5.78365 6.6665 6.33594 6.6665H25.6693C26.2216 6.6665 26.6693 7.11422 26.6693 7.6665C26.6693 8.21879 26.2216 8.6665 25.6693 8.6665H6.33594C5.78365 8.6665 5.33594 8.21879 5.33594 7.6665ZM5.33594 15.6665C5.33594 15.1142 5.78365 14.6665 6.33594 14.6665H25.6693C26.2216 14.6665 26.6693 15.1142 26.6693 15.6665C26.6693 16.2188 26.2216 16.6665 25.6693 16.6665H6.33594C5.78365 16.6665 5.33594 16.2188 5.33594 15.6665ZM6.33594 22.6665C5.78365 22.6665 5.33594 23.1142 5.33594 23.6665C5.33594 24.2188 5.78365 24.6665 6.33594 24.6665H25.6693C26.2216 24.6665 26.6693 24.2188 26.6693 23.6665C26.6693 23.1142 26.2216 22.6665 25.6693 22.6665H6.33594Z' fill='white'/%3E%3C/svg%3E%0A");
    }
    h1 > a {
      background: url(/images/common/marketwiz-logox3.png) center / 12rem 2.3rem no-repeat;
    }
    .head-right {
      padding: 0;
    }
    .chat {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: 0;
      background: url(/images/common/head-chat3x.png) center / 3.2rem no-repeat;
    }
  }
  &.bo {
    width: 100%;
    height: 8rem;
    padding-right: 0.8rem;
    background-color: #2d3036;
  }
  &.fo {
    &.login {
      .head-right {
        gap: 0;
        i {
          margin-right: 0.3rem;
        }
        span {
          margin-right: 0.8rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: var(--base-txt-color);
        }
        a {
          margin-right: 1.6rem;
          &::before {
            content: none;
          }
        }
      }
    }
    position: sticky;
    width: 100%;
    min-width: 1280px;
    align-items: center;
    height: 11.2rem;
    padding: 0 6.4rem;
    background-color: var(--white-color);
    border-bottom: 1px solid var(--line-color02);
    .head-left {
      h1 > a {
        width: 16rem;
        height: 3.2rem;
        background: url(/images/common/marketwiz-fo-logo.png) center / 100% no-repeat;
      }
    }
    .head-center {
      ul {
        display: flex;
        gap: 8rem;
        li {
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
          a,
          button {
            font-size: 2rem;
            font-weight: 700;
            line-height: 3rem;
            color: var(--base-txt-color);
            &:hover {
              color: var(--primary-color2);
            }
          }
        }
      }
    }
    .head-right {
      color: var(--sub-txt-color);
      gap: 3.3rem;
      align-items: center;

      .log-in {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: calc(100% + 1.6rem);
          transform: translateY(-50%);
          width: 0.1rem;
          height: 1.2rem;
          background-color: var(--bg-color-blue01);
        }
      }

      .user-info {
        cursor: pointer;
        color: var(--base-txt-color);
        &:hover {
          color: var(--primary-color2);
        }
      }
    }
  }
  h1 > a {
    display: block;
    width: 12rem;
    height: 2.3rem;
    font-size: 0;
    background: url(/images/common/marketwiz-logo.png) no-repeat;
  }
}
.head-left,
.head-right {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  &.full {
    flex: 1 1 auto;
  }
  * {
    flex-shrink: 0;
  }
}
.head-left {
  .user-name {
    font-size: 2rem;
  }
  .select-box {
    width: 14.6rem;
    margin-left: 2rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L11.7879 14.7879C11.905 14.905 12.095 14.905 12.2121 14.7879L17 10' stroke='%23FFFFFF' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-position: center right 1.2rem;
    border: 1px solid transparent;
    .init {
      padding-right: 0;
    }
    &.active {
      background-color: var(--white-color);
      .init {
        color: var(--strong-txt-color);
        border-top-left-radius: 0.6rem;
        border-top-right-radius: 0.6rem;
      }
    }
  }
}

.head-right {
  .user {
    margin-right: 3.2rem;
    padding-left: 2.8rem;
    font-weight: 700;
    font-size: 1.4rem;
    opacity: 0.7;
    &.logout {
      width: 7.6rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.6'%3E%3Cpath d='M17 2H7C5.3 2 4 3.3 4 5V11H12.6L10.3 8.7C9.9 8.3 9.9 7.7 10.3 7.3C10.7 6.9 11.3 6.9 11.7 7.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7L11.7 16.7C11.3 17.1 10.7 17.1 10.3 16.7C9.9 16.3 9.9 15.7 10.3 15.3L12.6 13H4V19C4 20.7 5.3 22 7 22H17C18.7 22 20 20.7 20 19V5C20 3.3 18.7 2 17 2Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat;
    }
    &.setting {
      width: 7.8rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.6'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.525 7.745L19.622 10.395L22 11V12.978L19.614 13.598L18.516 16.25L20 18L18 20C18 20 16.697 18.831 16.255 18.515L13.604 19.613L13 22H11L10.397 19.612L7.791 18.54L6 20L4 18L5.516 16.3L4.401 13.655L2 13V11L4.373 10.446L5.453 7.789L4 6L6 4L7.704 5.516L10.349 4.401L10.981 2H12.935L13.558 4.37L16.265 5.483L18 4L20 6L18.525 7.745ZM12 8.25C11.0054 8.25 10.0516 8.64509 9.34835 9.34835C8.64509 10.0516 8.25 11.0054 8.25 12C8.25 12.9946 8.64509 13.9484 9.34835 14.6517C10.0516 15.3549 11.0054 15.75 12 15.75C12.9946 15.75 13.9484 15.3549 14.6517 14.6517C15.3549 13.9484 15.75 12.9946 15.75 12C15.75 11.0054 15.3549 10.0516 14.6517 9.34835C13.9484 8.64509 12.9946 8.25 12 8.25ZM10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409Z' fill='white'/%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat;
    }
  }
}
.primary-bg {
  display: flex;
  align-items: center;
  margin-left: 0;
  padding: 2rem 4rem 2rem 2.8rem;
  background-color: var(--primary-color);
  .select-box {
    width: 14rem;
    margin-right: 0.8rem;
    background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L11.7879 14.7879C11.905 14.905 12.095 14.905 12.2121 14.7879L17 10' stroke='black' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A")
      center right 1.2rem / 2.4rem 2.4rem no-repeat;
    border-color: transparent;
    &.active {
      background: var(--white-color)
        url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 14L12.2121 9.21213C12.095 9.09498 11.905 9.09497 11.7879 9.21213L7 14' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        center right 1.2rem / 2.4rem 2.4rem no-repeat;
      .init {
        border-top-right-radius: 0.6rem;
        border-top-left-radius: 0.6rem;
      }
    }
    .init {
      color: var(--strong-txt-color);
      font-weight: 600;
    }
  }
  .input-box {
    display: flex;
    align-items: center;
    .input {
      flex: 1;
      width: 16rem;
      padding: 0;
      padding-right: 2rem;
      border: none;
      border-radius: 0;
      outline: none;
      background-color: var(--primary-color);
      &::placeholder {
        color: var(--strong-txt-color);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    .search-ty2 {
      font-size: 0;
      width: 2.4rem;
      height: 2.4rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.0008 21.0008L15.8038 15.8038M15.8038 15.8038C17.2104 14.3972 18.0006 12.4895 18.0006 10.5003C18.0006 8.51108 17.2104 6.60336 15.8038 5.19678C14.3972 3.79021 12.4895 3 10.5003 3C8.51108 3 6.60336 3.79021 5.19678 5.19678C3.79021 6.60336 3 8.51108 3 10.5003C3 12.4895 3.79021 14.3972 5.19678 15.8038C6.60336 17.2104 8.51108 18.0006 10.5003 18.0006C12.4895 18.0006 14.3972 17.2104 15.8038 15.8038Z' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}
.user-guide {
  display: block;
  width: 10.7rem;
  height: 4rem;
  margin: 0 4rem 0 2rem;
  line-height: 4rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
}
.user-alert {
  position: relative;
  display: block;
  width: 4rem;
  height: 4rem;
  background-size: 100%;
  span {
    position: absolute;
    z-index: 2;
    top: -1.4rem;
    right: -0.7rem;
    font-size: 1.1rem;
    font-weight: 700;
    color: #fff;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;
      height: 2.4rem;
      border-radius: 1.3rem;
      background-color: var(--alert-txt-color);
    }
  }
}
.chat {
  margin-right: 1.6rem;
  background-image: url(/images/common/head-chat.png);
}
.notify {
  background-image: url(/images/common/head-notice.png);
}
