.popup {
  &.w480 {
    width: 48rem;
  }
  &.w640 {
    width: 64rem;
  }
  &.w1024 {
    width: 102.4rem;
  }
  &.w95p {
    width: 95%;
  }

  &.open {
    display: block;
    position: static;
    transform: none;
    height: 100%;
    &.ty2 {
      height: auto;
    }
  }
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 28rem;
  height: 18rem;
  background-color: #fff;
  border-radius: 1.6rem;
  border: 1px solid var(--line-color01);
  box-shadow: 12px 12px 20px -10px rgba(0, 0, 0, 0.04), 8px 8px 40px 0px rgba(0, 0, 0, 0.16);
  z-index: 990;
  overflow: hidden;
  .txt-wrap {
    height: 13rem;
    position: relative;
    p {
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 600;
      text-align: center;
    }
  }
  .bt-wrap {
    display: flex;
    text-align: center;
    border-top: 1px solid var(--line-color02);
    button {
      width: 100%;
      height: 4.7rem;
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--primary-color2);
      &.cancel {
        color: var(--sub-txt-color);
        border-right: 1px solid var(--line-color02);
      }
      &.hover,
      &:hover {
        background-color: var(--disabled-bg-color);
      }
    }
  }
}
.has-tit {
  padding: 3.2rem;
  height: auto;
  .pop-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .pop-contents {
    margin-top: 2.4rem;

    &.guide {
      background-color: var(--alert-txt-color);
      opacity: 0.1;
    }
  }
  .pop-bottm {
    display: flex;
    justify-content: end;
    margin-top: 2.4rem;
    gap: 0.6rem;
  }
  .close {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: -0.8rem;
    background: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.01074 22.9906L16.0014 15.9999L22.9921 22.9906M22.9921 9.00928L16.0001 15.9999L9.01074 9.00928' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      center center / 3.2rem 3.2rem no-repeat;
  }
}
.pop-box {
  display: flex;
  .popup + p {
    margin-top: 2.4rem;
    color: #888888;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
  }
}
.panel {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 9999;
  overflow: hidden;
  .panel-contents {
    position: absolute;
    right: 0;
    top: 0;
    width: 102.4rem;
    height: 100vh;
    padding: 3.2rem;
    background-color: var(--white-color);
    box-shadow: -0.8rem 0 2rem 0 rgba($color: #000000, $alpha: 0.05);
    overflow: scroll;
    @include scroll-hide;
  }
  .panel-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8rem;
  }
  .panel-bottom {
    display: flex;
    justify-content: end;
    margin-top: 3.6rem;
    gap: 0.6rem;
  }
}
