// table
.table {
  position: relative;
  &.transparent {
    table {
      border: none;
      td {
        height: auto;
        padding: 0;
        border: none;
        .check-box {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 12px;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
    border-radius: 12px;
  }

  .middle {
    th,
    td {
      text-align: center;
    }
  }

  table {
    word-break: break-all;
    font-size: 1.4rem;
    line-height: 2rem;
    border-top: 0.1rem solid var(--line-color01);
    border-bottom: 0.1rem solid var(--line-color01);
    .add-code {
      td {
        background-color: #ffb7211f;
      }
    }
    .tr-hover {
      td {
        background-color: #e9eff9;
      }
    }
    th,
    td {
      height: 4.4rem;
      padding: 1.2rem;
      text-align: left;
      word-break: break-all;
      border-top: 0.1rem solid var(--line-color03);
      border-right: 0.1rem solid var(--line-color03);
      &.bd-left {
        border-left: 0.1rem solid var(--line-color03);
      }
      &.bd-right {
        border-width: 1px !important;
        border-right: 0.1rem solid var(--line-color03);
      }
      &.small-pd {
        padding: 0.6rem 1.2rem;
        line-height: 1.6rem;
        .button {
          margin-right: 0;
        }
      }
      &.dark-bg {
        font-weight: 700;
        background-color: var(--bg-color-blue02);
        border-top: 0.1rem solid var(--line-color01);
      }
      .img {
        width: 11.6rem;
        height: 11.6rem;
      }
      .select-box {
        background-color: var(--white-color);
      }
    }
    th {
      background: #f4f6f9;
      font-weight: normal;
      font-size: 1.4rem;
      vertical-align: middle;
      .sub-txt {
        font-weight: 700;
        text-align: left;
        color: var(--strong-txt-color);
      }
      .sub-txt2 {
        font-weight: 700;
        text-align: left;
        color: var(--strong-txt-color);
      }
      &.required::after {
        content: '*';
        display: inline-block;
        margin-left: 0.4rem;
        color: var(--alert-txt-color);
      }
    }
    th:last-child,
    td:last-child {
      border-right-width: 0;
    }
    thead {
      th {
        padding-top: 0.9rem;
        padding-bottom: 0.9rem;
        text-align: center;
      }
    }
    tbody {
      background: #fff;
    }
    thead tr:first-child th,
    tbody tr:first-child th,
    tbody tr:first-child td {
      border-top: 0;
    }
    thead tr:first-child th {
      border-bottom: 1px solid var(--line-color03);
    }
    thead + tbody th ~ td {
      text-align: left;
    }
    colgroup + tbody th {
      border-right-width: 0;
    }
    colgroup + thead th {
      border-right-width: 1px;
    }
    .total_right {
      text-align: right;
    }
    tfoot {
      th,
      td {
        background-color: #f8f8f8;
      }
      th {
        font-weight: normal;
        border-top: 0.1rem solid #eee;
      }
      td {
        font-weight: 500;
        text-align: right;
        border-top: 0.1rem solid #fff;
        border-right: 0.1rem solid #fff;
      }
      tr:first-child td {
        border-top: 0.1rem solid #eee;
      }
    }
    .flex {
      .radio-box + .radio-box {
        margin-left: 2.4rem;
        margin-top: 0;
      }
    }
    .radio-box + .radio-box {
      margin-left: 0;
      margin-top: 1.2rem;
    }
    .bd-line {
      border-bottom: 1px solid var(--line-color01);
    }
    .bd-line-2 {
      border-bottom: 1px solid var(--line-color03);
    }
    .bd-top {
      border-top: 1px solid var(--line-color01);
    }
    .bd-right {
      border-right: 1px solid var(--line-color03);
    }
    .bd-r-none {
      border-right-width: 0;
    }
    &.layout-fix {
      margin-bottom: 1.6rem;
      table-layout: fixed;
    }
  }
  &.bg-blue {
    th {
      background-color: #e9eff9;
    }
  }
  &.cs-center {
    th,
    td {
      height: 6.4rem;
      border-right-width: 0;
      color: var(--sub-txt-color);
      font-size: 1.4rem;
      line-height: 2rem;
      &:first-child {
        padding-left: 3.2rem;
      }
      &:last-child {
        padding-right: 3.2rem;
      }
      &.notice-detail {
        padding: 3.2rem;
        color: var(--strong-txt-color);
      }
    }
    th {
      font-weight: 700;
      color: var(--strong-txt-color);
    }
    td {
      color: var(--sub-txt-color);
    }
  }
  .no-label.sm-check {
    .lbl {
      &::before,
      &::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
.tb-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
  font-weight: 700;
}
td {
  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: var(--strong-txt-color);
  }
  h5 {
    margin-top: 1.2rem;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    color: var(--strong-txt-color);
  }
}