.contents {
  .select-box {
    + .input-box,
    + .select-box,
    + .text-area {
      margin-left: 1rem;
    }
  }
}
.search-wrap {
  margin-top: 3.2rem;
  &.ty2 {
    margin: 2rem 0;
    padding: 3.2rem;
    border: 1px solid var(--line-color02);
    border-radius: 0.6rem;
  }
  &.ty3 {
    margin: 3.2rem 0 2.4rem;
    padding: 1.6rem;
    border: 1px solid var(--line-color02);
    border-radius: 0.6rem;
  }
  &.scroll-y {
    height: 100%;
    padding: 2.4rem;
    border: 1px solid var(--line-color02);
    border-radius: 0.6rem;
    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
      color: var(--sub-txt-color);
    }
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 10px;
      border: 4px solid #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0);
    }
  }
  > dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.6rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    dt {
      width: 12rem;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 600;
    }
    .cr-situation {
      display: flex;
      align-items: center;
      li {
        position: relative;
        margin-left: 1.6rem;
        padding-left: 1.6rem;
        &:first-child,
        &:last-child {
          margin-left: 0;
          padding-left: 0;
          &:after {
            content: none;
          }
        }
        &:last-child {
          margin-left: 1.2rem;
        }
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 0.1rem;
          height: 1.2rem;
          background-color: var(--line-color01);
        }
      }
      dl {
        display: flex;
        align-items: center;
      }
      dt {
        width: fit-content;
        margin-right: 0.8rem;
        font-weight: 400;
      }
      dd {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
  .flex {
    flex-wrap: wrap;
  }
}


.search-wrapper {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;

  .title {
    flex-wrap: wrap;
  }

  &.ty2 {
    margin: 2rem 0;
    padding: 3.2rem;
    border: 1px solid #ddd;
    border-radius: 0.6rem;
  }

  &.ty3 {
    margin: 3.2rem 0 2.4rem;
    padding: 1.6rem;
    border: 1px solid #ddd;
    border-radius: 0.6rem;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 2em;

    thead {
      border-bottom: 3px solid #ddd;

      th {
        padding: 0.35em 0 .35em;
        font-weight: 400;
        font-size: 1.25em;
      }
    }

    tbody {
      border-bottom: 3px solid #ddd;

      tr {
        border-bottom: 2px solid #ddd;

        td {
          padding: .75em 0;

          a {
            color: #ddd;
            text-decoration: none;
            display: inline-block;
            margin: 0 .5em 0 0;
          }
        }
      }
    }
  }

  &.scroll-y {
    height: 100%;
    padding: 2.4rem;
    border: 1px solid var(--line-color02);
    border-radius: 0.6rem;

    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
      padding: 1rem 0;
      color: var(--sub-txt-color);
    }

    .title {
      font-size: 1.7rem;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 10px;
      border: 4px solid #fff;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(255, 255, 255, 0);
    }
  }

  >dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1.6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    dt {
      width: 12rem;
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 600;
    }

    .cr-situation {
      display: flex;
      align-items: center;

      li {
        position: relative;
        margin-left: 1.6rem;
        padding-left: 1.6rem;

        &:first-child,
        &:last-child {
          margin-left: 0;
          padding-left: 0;

          &:after {
            content: none;
          }
        }

        &:last-child {
          margin-left: 1.2rem;
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 0.1rem;
          height: 1.2rem;
          background-color: var(--line-color01);
        }
      }

      dl {
        display: flex;
        align-items: center;
      }

      dt {
        width: fit-content;
        margin-right: 0.8rem;
        font-weight: 400;
      }

      dd {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }

  .flex {
    flex-wrap: wrap;
  }
}

/* 반응형 스타일 */
@media (max-width: 1024px) {
  .search-wrapper {
    margin-top: 2rem;

    &.ty2,
    &.ty3 {
      padding: 2rem;
    }

    table {
      thead th {
        font-size: 1.1em;
      }
    }
  }
}

@media (max-width: 768px) {
  .search-wrapper {
    margin-top: 1.6rem;

    &.ty2,
    &.ty3 {
      padding: 1.6rem;
      margin: 1.6rem 0;
    }

    table {
      thead th {
        font-size: 1em;
      }

      tbody td {
        font-size: 0.9em;
      }
    }

    >dl {
      flex-direction: column;

      dt {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width: 480px) {
  .search-wrapper {
    margin-top: 1.2rem;

    &.ty2,
    &.ty3 {
      padding: 1.2rem;
      margin: 1.2rem 0;
    }

    table {
      thead th {
        font-size: 0.9em;
      }
    }

    .title {
      font-size: 1.4rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

.select-box,
.input {
  height: 3.2rem;
  padding: 0rem 1.2rem;
  border-radius: 0.6rem;
  border: 1px solid var(--line-color01);
  font-size: 1.4rem;
  &.large {
    height: 4.8rem;
    button {
      line-height: 4.8rem;
      padding: 0 0.8rem;
      &.init {
        padding: 0;
      }
    }
  }
  &.middle {
    height: 4rem;
    button {
      line-height: 4rem;
      padding: 0 0.8rem;
      &.init {
        padding: 0;
      }
    }
  }
  &::placeholder {
    color: var(--sub-txt-color2);
  }
  &:hover,
  &.hover {
    border: 1px solid var(--primary-color2);
  }
  &:focus,
  &.focused {
    outline: none;
    border: 1px solid var(--strong-txt-color);
  }
  &:disabled {
    color: var(--sub-txt-color2);
    background-color: var(--disabled-bg-color);
    border: 1px solid var(--line-color02);
  }
  //   &:required {
  //     border: 1px solid var(--alert-txt-color);
  //   }
}
.search-box {
  display: flex;
}
.t-center .check-box .checkbox {
  left: 50%;
  transform: translate(-50%, -50%);
}
.check-box {
  &.switch {
    &.btn-ty {
      position: relative;
      width: 16rem;
      height: 3.2rem;
      .checkbox {
        width: 16rem;
        height: 3.2rem;
        cursor: pointer;
      }
      span {
        position: absolute;
        left: 2rem;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2rem;
      }
      &::before,
      &:after {
        content: '';
        position: absolute;
      }
      &::before {
        z-index: -1;
        border-radius: 0.5rem;
        top: 1px;
        right: 0;
        bottom: 1px;
        left: 0;
        background-color: var(--white-color);
      }
      &::after {
        z-index: -2;
        border-radius: 0.6rem;
        top: 0;
        right: -1px;
        bottom: 0;
        left: -1px;
        background: linear-gradient(180deg, #aeb6d0 76.39%, #939ab3 100%);
      }
      .switch-label {
        width: 4.4rem;
        height: 2rem;
        right: 2rem;
        left: auto;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    position: relative;
    cursor: pointer;
    display: inline-block;
    width: 4.4rem;
    height: 2rem;
    padding: 0;
    border-radius: 1.4rem;
    .checkbox {
      width: 4.4rem;
      height: 2rem;
      &:disabled {
        + .switch-label {
          background: var(--sub-txt-color2);
          &::before {
            background: url("data:image/svg+xml,%0A%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L5 5L9 9M9 1L4.99924 5L1 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
              center / 100% no-repeat;
          }
        }
      }
      &:checked + .switch-label {
        background: var(--primary-color2);
        &:after {
          left: auto;
          right: 2px;
        }
        &::before {
          left: 8px;
          width: 1rem;
          height: 1rem;
          background: url("data:image/svg+xml,%0A%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.7L3.85714 8.5L11 1.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
            center / 100% no-repeat;
        }
      }
    }
    .switch-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.4rem;
      background: #ff602b;
      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: calc(100% - 18px);
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        padding: 0;
        border-radius: 50%;
        background: var(--white-color);
        transition: 0.2s;
      }
      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        left: auto;
        width: 1rem;
        height: 1rem;
        background: url("data:image/svg+xml,%0A%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L5 5L9 9M9 1L4.99924 5L1 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          center / 100% no-repeat;
      }
    }
  }
  position: relative;
  & + .check-box {
    margin-left: 2rem;
  }
  &.no-label {
    .lbl {
      padding-left: 2.4rem;
    }
    .checkbox.center {
      left: 50%;
      transform: translateX(-50%);
      & + label::before {
        left: 50%;
        transform: translateX(-50%);
      }
      & + label {
        padding: 0;
      }
    }
  }
  &.no-label.sm-check {
    .lbl {
      padding-left: 2rem;
      
    }

    

    .lbl2 {
      padding-left: 3rem;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
  &.margin-bottom {
    margin-bottom: 2rem;
  }
  &.has-line.sm-check {
    label {
      width: 100%;
      height: 4.8rem;
      border: 1px solid var(--line-color01);
      border-radius: 0.6rem;
      color: var(--sub-txt-color2);
    }
    .checkbox {
      left: 1.6rem;

      &:checked {
        & + label {
          color: var(--primary-color2);
          border: 1px solid var(--primary-color2);
          &::before {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='6' fill='%23055DB5'/%3E%3Cpath d='M5.14062 10.6944L7.9184 13.4722L14.8628 6.52777' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          }
        }
      }
    }
    .checkbox + label {
      padding: 0;
      padding-left: 4.4rem;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 4.8rem;
      &::before {
        left: 1.6rem;
      }
    }
    & + .has-line.sm-check {
      margin-top: 0.8rem;
      margin-left: 0;
    }
  }
  &.va-top {
    .checkbox {
      top: 0.25rem;
      transform: translateY(0);
      & + label::before {
        top: 0.25rem;
        transform: translateY(0);
      }
    }
  }
  .lbl {
    display: inline-block;
  }
  .checkbox {
    position: absolute;
    left: 0;
    // top: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    margin: 0;
    z-index: 2;
    border-radius: 0.6rem;
    opacity: 0;
  }
  .checkbox + label {
    padding-left: 3.2rem;
    font-size: 1.5rem;
    // line-height: 2.4rem;
    line-height: 2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    vertical-align: middle;
    cursor: pointer;
  }
  .checkbox:checked + label {
    font-weight: 600;
  }
  .checkbox:disabled + label {
    color: var(--sub-txt-color2);
  }
  .checkbox + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 2.4rem;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .checkbox:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='8' fill='%2342444E'/%3E%3Cpath d='M6.16666 12.8333L9.49999 16.1666L17.8333 7.83325' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  .checkbox + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill='white'/%3E%3Cpath d='M6.16666 12.8333L9.49999 16.1666L17.8333 7.83325' stroke='%23CCCCCC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='7.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
  }
  .checkbox:disabled + label::before {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='7.5' fill='%23F7F7F7'/%3E%3Cpath d='M6.16666 12.8333L9.49999 16.1666L17.8333 7.83325' stroke='%23CCCCCC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='7.5' stroke='%23EEEEEE'/%3E%3C/svg%3E%0A");
  }
  .checkbox:disabled:checked + label:before {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.4'%3E%3Crect width='24' height='24' rx='8' fill='%2342444E'/%3E%3Cpath d='M6.16666 12.8333L9.49999 16.1666L17.8333 7.83325' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &.sm-check {
    line-height: 2rem;
    & + .sm-check {
      margin-left: 1.6rem;
    }
    input {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
    }
    .checkbox + label {
      padding-left: 2.8rem;
      font-size: 1.4rem;
      // height: 2rem;
      line-height: 2rem;
    }
    .checkbox + label:before {
      width: 2rem;
      height: 2rem;
    }
  }
  .menu-authority {
  }
}
.result-wrap {
  display: flex;
}
.left-box {
  width: 19.2rem;
  height: 100%;
  margin-right: 4rem;
}
.summary-box {
  width: 19.2rem;
  height: 100%;
  margin-right: 4rem;
  background-color: #f4f9ff;
  border: 1px solid #dfe6f0;
  border-radius: 1.2rem;
  font-weight: 600;
  li {
    cursor: pointer;
    padding: 1.6rem 2rem;
    border-bottom: 1px solid var(--line-color02);
    &:first-child {
      padding-top: 2rem;
      border-radius: 1.2rem 1.2rem 0 0;
    }
    &:last-child {
      padding-bottom: 2rem;
      border-bottom: none;
      border-radius: 0 0 1.2rem 1.2rem;
    }
    &.active {
      cursor: auto;
      background-color: #fff;
    }
    p {
      margin-top: 0.4rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
.result-box {
  -webkit-box-flex: 1;
  flex: 1;
  .list-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-left: 1.6rem;
  }
}
.progress-box {
  display: block;
  margin-top: 0;
  padding: 8rem 0;
  text-align: center;
  font-weight: 600;
  border-top: 1px solid var(--line-color01);
  border-bottom: 1px solid var(--line-color01);
  .no-result {
    width: 5.6rem;
    height: 5.6rem;
    margin: 0 auto 1.6rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28' cy='28' r='28' fill='%23EEEEEE'/%3E%3Cpath d='M30.0856 14L29.8363 34.0411H26.6336L26.3651 14H30.0856ZM26.25 40.1397C26.25 39.5644 26.4226 39.0785 26.7678 38.6822C27.1258 38.2858 27.65 38.0877 28.3404 38.0877C29.018 38.0877 29.5358 38.2858 29.8938 38.6822C30.2646 39.0785 30.45 39.5644 30.45 40.1397C30.45 40.6895 30.2646 41.1625 29.8938 41.5589C29.5358 41.9552 29.018 42.1534 28.3404 42.1534C27.65 42.1534 27.1258 41.9552 26.7678 41.5589C26.4226 41.1625 26.25 40.6895 26.25 40.1397Z' fill='%23C6C6C6'/%3E%3C/svg%3E%0A");
  }
  .no-result-tt {
    color: var(--sub-txt-color2);
    font-weight: 400;
  }
  .progress-wrap {
    position: relative;
    width: 48rem;
    height: 1.2rem;
    margin: 0 auto 2.4rem;
    background: var(--disabled-bg-color);
    border: 1px solid var(--line-color02);
    border-radius: 0.6rem;
    overflow: hidden;
    .progress {
      position: absolute;
      width: 120%;
      height: 100%;
      background: var(--primary-color2);
      border-radius: 0.6rem;
      top: 0;
      animation: wheel 2s infinite;
    }
    @keyframes wheel {
      0% {
        left: -121%;
      }
      100% {
        left: 101%;
      }
    }
  }
}

.product-list {
  .left,
  .right {
    display: flex;
    align-items: center;
  }
  .item-wrap {
    display: grid;
    justify-content: space-between;
    grid-template-columns: calc(50% - 2.4rem) calc(50% - 2.4rem);
    margin-top: 1.6rem;
    padding-top: 3.2rem;
    border-top: 1px solid var(--line-color01);
    border-bottom: 1px solid var(--line-color01);

    .item {
      display: flex;
      position: relative;
      height: 10.4rem;
      margin-bottom: 3.2rem;
      .check-box {
        position: absolute;
        top: 4px;
        left: 0.4rem;
      }
      .txt-wrap {
        margin-left: 1.6rem;
        span {
          font-size: 1.4rem;
          line-height: 2rem;
          color: var(--sub-txt-color);
        }
        a {
          display: block;
          margin-top: 0.2rem;
          text-decoration: underline;
          line-height: 2rem;
          color: var(--primary-color2);
        }
        .item-price {
          position: absolute;
          right: 0;
          bottom: 0;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 600;
        }
      }
    }
  }
}
.list-summary {
  display: flex;
  justify-content: space-between;
  label {
    line-height: 2.4rem;
    font-size: 1.6rem;
    font-weight: 700;
  }
  .result {
    margin: 0 1.2rem;
    font-size: 1.4rem;
    line-height: 2rem;
    em {
      font-weight: 600;
    }
  }
}
input[type='date'] {
  width: 16rem;
  background: var(--white-color)
    url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.625 2.5V4.375M14.375 2.5V4.375M2.5 15.625V6.25C2.5 5.75272 2.69754 5.27581 3.04917 4.92417C3.40081 4.57254 3.87772 4.375 4.375 4.375H15.625C16.1223 4.375 16.5992 4.57254 16.9508 4.92417C17.3025 5.27581 17.5 5.75272 17.5 6.25V15.625M2.5 15.625C2.5 16.1223 2.69754 16.5992 3.04917 16.9508C3.40081 17.3025 3.87772 17.5 4.375 17.5H15.625C16.1223 17.5 16.5992 17.3025 16.9508 16.9508C17.3025 16.5992 17.5 16.1223 17.5 15.625M2.5 15.625V9.375C2.5 8.87772 2.69754 8.40081 3.04917 8.04917C3.40081 7.69754 3.87772 7.5 4.375 7.5H15.625C16.1223 7.5 16.5992 7.69754 16.9508 8.04917C17.3025 8.40081 17.5 8.87772 17.5 9.375V15.625' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.65234 10.2865V15.0001H7.66927V11.2175H7.64323L6.5625 11.8946V11.0287L7.72786 10.2865H8.65234Z' fill='black'/%3E%3Cpath d='M9.61276 15.0001L9.60625 14.2904L11.299 12.7345C11.7384 12.3145 11.976 12.0541 11.976 11.6798C11.976 11.2598 11.6603 10.9994 11.2273 11.0027C10.7846 10.9994 10.4949 11.2794 10.4982 11.7319H9.56719C9.56393 10.8106 10.2475 10.2214 11.2404 10.2214C12.2495 10.2214 12.9201 10.7976 12.9201 11.6147C12.9201 12.1518 12.6596 12.5912 11.6896 13.4701L10.9734 14.1667V14.1928H12.9852V15.0001H9.61276Z' fill='black'/%3E%3C/svg%3E%0A")
    right 0.8rem center / 2rem 2rem no-repeat;

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  &:disabled {
    background-color: var(--disabled-bg-color);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.625 2.5V4.375M14.375 2.5V4.375M2.5 15.625V6.25C2.5 5.75272 2.69754 5.27581 3.04917 4.92417C3.40081 4.57254 3.87772 4.375 4.375 4.375H15.625C16.1223 4.375 16.5992 4.57254 16.9508 4.92417C17.3025 5.27581 17.5 5.75272 17.5 6.25V15.625M2.5 15.625C2.5 16.1223 2.69754 16.5992 3.04917 16.9508C3.40081 17.3025 3.87772 17.5 4.375 17.5H15.625C16.1223 17.5 16.5992 17.3025 16.9508 16.9508C17.3025 16.5992 17.5 16.1223 17.5 15.625M2.5 15.625V9.375C2.5 8.87772 2.69754 8.40081 3.04917 8.04917C3.40081 7.69754 3.87772 7.5 4.375 7.5H15.625C16.1223 7.5 16.5992 7.69754 16.9508 8.04917C17.3025 8.40081 17.5 8.87772 17.5 9.375V15.625' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.65234 10.2865V15.0001H7.66927V11.2175H7.64323L6.5625 11.8946V11.0287L7.72786 10.2865H8.65234Z' fill='%23999999'/%3E%3Cpath d='M9.61276 15.0001L9.60625 14.2904L11.299 12.7345C11.7384 12.3145 11.976 12.0541 11.976 11.6798C11.976 11.2598 11.6603 10.9994 11.2273 11.0027C10.7846 10.9994 10.4949 11.2794 10.4982 11.7319H9.56719C9.56393 10.8106 10.2475 10.2214 11.2404 10.2214C12.2495 10.2214 12.9201 10.7976 12.9201 11.6147C12.9201 12.1518 12.6596 12.5912 11.6896 13.4701L10.9734 14.1667V14.1928H12.9852V15.0001H9.61276Z' fill='%23999999'/%3E%3C/svg%3E%0A");
    border: 1px solid var(--line-color02);
  }
  &.middle,
  &.large {
    background: var(--white-color)
      url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 4V7M23 4V7M4 25V10C4 9.20435 4.31607 8.44129 4.87868 7.87868C5.44129 7.31607 6.20435 7 7 7H25C25.7957 7 26.5587 7.31607 27.1213 7.87868C27.6839 8.44129 28 9.20435 28 10V25M4 25C4 25.7957 4.31607 26.5587 4.87868 27.1213C5.44129 27.6839 6.20435 28 7 28H25C25.7957 28 26.5587 27.6839 27.1213 27.1213C27.6839 26.5587 28 25.7957 28 25M4 25V15C4 14.2044 4.31607 13.4413 4.87868 12.8787C5.44129 12.3161 6.20435 12 7 12H25C25.7957 12 26.5587 12.3161 27.1213 12.8787C27.6839 13.4413 28 14.2044 28 15V25' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M13.8438 16.4583V24H12.2708V17.9479H12.2292L10.5 19.0312V17.6458L12.3646 16.4583H13.8438Z' fill='black'/%3E%3Cpath d='M15.3804 24L15.37 22.8645L18.0783 20.375C18.7815 19.7031 19.1617 19.2864 19.1617 18.6875C19.1617 18.0156 18.6565 17.5989 17.9638 17.6041C17.2554 17.5989 16.7919 18.0468 16.7971 18.7708H15.3075C15.3023 17.2968 16.396 16.3541 17.9846 16.3541C19.5992 16.3541 20.6721 17.276 20.6721 18.5833C20.6721 19.4427 20.2554 20.1458 18.7033 21.552L17.5575 22.6666V22.7083H20.7763V24H15.3804Z' fill='black'/%3E%3C/svg%3E%0A")
      right 0.8rem center / 3.2rem 3.2rem no-repeat;
  }
}
.text-area {
  background: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  overflow: hidden;
  border: 1px solid var(--line-color01);
  border-radius: 0.6rem;
  textarea {
    display: block;
    width: 100%;
    min-height: 5.2rem;
    height: 100%;
    padding: 0.6rem 1.2rem;
    padding-right: 0.6rem;
    box-sizing: border-box;
    outline: none;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    font-size: 1.4rem;
    overflow-y: scroll;
    resize: none;
    outline-color: #fe6b8b;
    &::-webkit-scrollbar {
      width: 1.6rem;
    }
    &::-webkit-scrollbar-thumb {
      border: 0.6rem solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 20rem;
      background-color: var(--bg-color-blue02);
    }
    &::placeholder {
      line-height: 2rem;
    }
  }
  &.focused {
    border: 1px solid var(--strong-txt-color);
  }
  &.hover,
  &:hover {
    border: 1px solid var(--primary-color2);
  }
  &.validation {
    border: 1px solid var(--alert-txt-color);
  }
  &.disabled {
    background-color: var(--disabled-bg-color);
    border: 1px solid var(--line-color02);
    textarea {
      color: var(--sub-txt-color2);
      background-color: var(--disabled-bg-color);
    }
  }
  .counter {
    padding-right: 0.6rem;
    font-size: 1.1rem;
    text-align: right;
  }
}

.mo .radio-box {
  line-height: 3.6rem;
}
.radio-box + .radio-box {
  margin-left: 2.4rem;
}
.radio-box {
  position: relative;
  .radio {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    margin: 0;
    z-index: 2;
    opacity: 0;
    + label {
      cursor: pointer;
      position: relative;
      padding-left: 3.2rem;
      z-index: 3;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2.4rem;
        height: 2.4rem;
        background-size: 2.4rem 2.4rem;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
      }
    }
    &:checked + label {
      font-weight: 600;
    }
    &:checked + label::before {
      background-size: 2.7rem 2.7rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%2342444E'/%3E%3Cg filter='url(%23filter0_d_436_14)'%3E%3Crect x='6' y='6' width='12' height='12' rx='6' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_436_14' x='3' y='3' width='24' height='24' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='3' dy='3'/%3E%3CfeGaussianBlur stdDeviation='3'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_436_14'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_436_14' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    }
    &:disabled + label {
      color: var(--sub-txt-color2);
    }
    &:disabled + label::before {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='%23F3F5F7'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
    }
    &:disabled:checked + label::before {
      background-size: 2.4rem 2.4rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_436_1366)'%3E%3Crect width='24' height='24' rx='12' fill='%23CCCCCC'/%3E%3Cg filter='url(%23filter0_d_436_1366)'%3E%3Crect x='6' y='6' width='12' height='12' rx='6' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_436_1366' x='3' y='3' width='24' height='24' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='3' dy='3'/%3E%3CfeGaussianBlur stdDeviation='3'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_436_1366'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_436_1366' result='shape'/%3E%3C/filter%3E%3CclipPath id='clip0_436_1366'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
  }
  &.small {
    .radio + label {
      padding-left: 2.6rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }
    .radio {
      + label::before {
        width: 2rem;
        height: 2rem;
        background-size: 2rem 2rem;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='white'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
      }
      &:checked + label::before {
        background-size: 2.2rem 2.2rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%2342444E'/%3E%3Cg filter='url(%23filter0_d_436_14)'%3E%3Crect x='6' y='6' width='12' height='12' rx='6' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_436_14' x='3' y='3' width='24' height='24' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='3' dy='3'/%3E%3CfeGaussianBlur stdDeviation='3'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_436_14'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_436_14' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
      }
      &:disabled + label::before {
        background-size: 2rem 2rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' fill='%23F3F5F7'/%3E%3Crect x='0.5' y='0.5' width='23' height='23' rx='11.5' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
      }
      &:disabled:checked + label::before {
        background-size: 2rem 2rem;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_436_1366)'%3E%3Crect width='24' height='24' rx='12' fill='%23CCCCCC'/%3E%3Cg filter='url(%23filter0_d_436_1366)'%3E%3Crect x='6' y='6' width='12' height='12' rx='6' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d_436_1366' x='3' y='3' width='24' height='24' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dx='3' dy='3'/%3E%3CfeGaussianBlur stdDeviation='3'/%3E%3CfeComposite in2='hardAlpha' operator='out'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_436_1366'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_436_1366' result='shape'/%3E%3C/filter%3E%3CclipPath id='clip0_436_1366'%3E%3Crect width='24' height='24' rx='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      }
    }
  }
  &.ty2 {
    position: relative;
    + .ty2 {
      margin-left: 0;
    }
    // overflow: hidden;
    display: flex;
    input {
      width: 100%;
      & + label {
        padding-left: 2rem;
        &::before {
          content: none;
        }
      }
    }
    &:first-of-type label {
      border-top-left-radius: 0.6rem;
      border-bottom-left-radius: 0.6rem;
    }
    &:last-of-type label {
      border-top-right-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }
    label {
      padding: 0.6rem 2rem;
      background-color: var(--disabled-bg-color);
      border: 1px solid var(--line-color02);
      border-right-color: var(--disabled-bg-color);
      line-height: 1.8rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #eee;
      }
    }
    input:checked + label {
      background: var(--white-color);
      border: 1px solid var(--strong-txt-color);
    }
  }
  &.ty3 {
    position: relative;
    flex: 1;
    + .ty3 {
      margin-left: 0.8rem;
    }
    display: flex;
    input {
      width: 100%;
      & + label {
        &::before {
          content: none;
        }
      }
    }
    label {
      width: 100%;
      padding: 1.4rem 0;
      background-color: var(--white-color);
      border: 1px solid var(--line-color01);
      border-radius: 0.6rem;
      line-height: 1.8rem;
      text-align: center;
      cursor: pointer;
      color: var(--sub-txt-color2);
      font-weight: 600;
    }
    input:checked + label {
      background: var(--white-color);
      border: 1px solid var(--primary-color2);
      color: var(--primary-color2);
    }
  }
}
.page_wrap {
  margin-top: 4rem;
}
.page_nation {
  .hover {
    a {
      color: var(--primary-color2);
    }
    .arrow {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 15L7.5 10L12.5 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
    .pprev,
    .nnext {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15L5 10L10 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 15L10 10L15 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  li a,
  li button {
    display: block;
    width: 3.2rem;
    height: 2.4rem;
    text-align: center;
    background-color: #fff;
    font-size: 1.5rem;
    line-height: 2.4rem;
    text-decoration: none;
    &:hover {
      color: var(--primary-color2);
    }
    &.active {
      font-weight: 700;
    }
  }
  .arrow {
    width: 2rem;
    margin: 0;
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 15L7.5 10L12.5 5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 15L7.5 10L12.5 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
  .pprev {
    margin-left: 0;
  }
  .pprev,
  .nnext {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15L5 10L10 5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 15L10 10L15 5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15L5 10L10 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 15L10 10L15 5' stroke='%231349B8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }

  .next,
  .nnext {
    transform: rotate(180deg);
  }
  &.disabled {
    li a {
      color: var(--sub-txt-color2);
    }
    .arrow {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 15L7.5 10L12.5 5' stroke='%23cccccc' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
    .pprev,
    .nnext {
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 15L5 10L10 5' stroke='%23cccccc' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M15 15L10 10L15 5' stroke='%23cccccc' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
  }
}
.select-box {
  width: 16rem;
  position: relative;
  border: 0.1rem solid var(--line-color01);
  border-radius: 0.6rem;
  background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 10L11.7879 14.7879C11.905 14.905 12.095 14.905 12.2121 14.7879L17 10' stroke='%23999999' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A")
    center right 0.4rem / 2.4rem 2.4rem no-repeat;
  &.modify {
    background: none;
  }
  &.active,
  &:active {
    border: 1px solid var(--strong-txt-color);
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 14L12.2121 9.21213C12.095 9.09498 11.905 9.09497 11.7879 9.21213L7 14' stroke='%23999999' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    &.modify {
      background: none;
    }
    ul {
      display: block;
      &:hover {
        border: 1px solid var(--primary-color2);
        border-top-width: 0;
      }
    }
  }
  &.in-bottom {
    ul {
      top: auto;
      bottom: 100%;
      margin-top: 0;
      margin-bottom: -6px;
      padding-top: 4px;
      padding-bottom: 6px;
      border-bottom-width: 0;
      border-radius: 0.6rem 0.6rem 0 0;
      border-top-width: 1px;
      &.hover,
      &:hover {
        border-bottom-width: 0;
        border-top-width: 1px;
      }
    }
  }

  button {
    width: 100%;
    // height: 3rem;
    line-height: 1.8rem;
    padding: 0.6rem 0.8rem;
    border-radius: 3px;
    vertical-align: top;
    &.init {
      position: relative;
      z-index: 2;
      padding: 0.6rem;
      padding-left: 0;
      padding-right: 2.4rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      &:disabled {
        background: transparent;
      }
    }
    &.placeholder {
      color: var(--sub-txt-color2);
    }
  }

  &.hover,
  &:hover {
    border: 1px solid var(--primary-color2);
  }
  &.disabled {
    color: var(--sub-txt-color2);
    background-color: var(--disabled-bg-color) !important;
    border: 1px solid var(--line-color02);
    overflow: hidden;
    &:hover {
      border: 0.1rem solid var(--line-color02);
    }
  }
  &.validation {
    border: 1px solid var(--alert-txt-color);
    li {
      border-left-color: var(--alert-txt-color);
      border-right-color: var(--alert-txt-color);
    }
    li:last-child {
      border-bottom-color: var(--alert-txt-color);
    }
  }
  ul {
    display: none;
    position: absolute;
    z-index: 10;
    left: -1px;
    right: -1px;
    top: 100%;
    margin-top: -6px;
    padding-top: 6px;
    padding-bottom: 2px;
    line-height: 3.2rem;
    background-color: var(--white-color);
    border: 1px solid var(--strong-txt-color);
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    border-top-width: 0;
  }
  li,
  option {
    // height: 3.2rem;
    padding: 0 0.4rem;
    position: relative;
    z-index: 10;
    width: 100%;
    color: var(--base-txt-color);
    button {
      word-break: break-all;
      &.selected {
        font-weight: 600;
      }
      &.disabled {
        color: var(--sub-txt-color2);
      }
      &.hover,
      &:hover {
        background: #e9eff9;
      }
    }
  }
}
.button-wrap {
  font-size: 0;
  .button {
    margin-right: 0.8rem;
    &.mini {
      margin-right: 0.4rem;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

