h1,
h2,
h3,
h4,
h5,
h6,
html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
input,
select,
textarea,
button {
  margin: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--base-txt-color);
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
legend,
caption {
  overflow: hidden;
  width: 0;
  height: 0;
  color: transparent;
  clip: rect(0 0 0 0);
  opacity: 0;
  @include text-blind;
}
li {
  list-style: none;
}
// pre{white-space:pre-line;}
code {
  font-family: inherit;
}
table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a[href*='tel'] {
  cursor: default;
}
button,
input[type='button'] {
  border: none;
  background: none;
  cursor: pointer;
  letter-spacing: inherit;
  padding: 0;
  color: inherit;
  text-align: inherit;
}
optgroup,
pre {
  font-family: inherit;
}
i,
em {
  font-style: normal;
}
hr {
  margin: 2rem 0;
  border: 0;
  border-bottom: 0.1rem solid #ddd;
}
sub {
  vertical-align: 0;
}
iframe,
textarea {
  width: 100%;
}

select,
input,
textarea,
button,
// img,
iframe {
  vertical-align: middle;
}
img:not([src]),
img[src=''] {
  opacity: 0;
}
select,
input,
textarea,
button,
optgroup {
  font-family: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  line-height: inherit;
}
option:disabled,
input:disabled,
button:disabled {
  background: inherit;
  color: inherit;
}
button:disabled,
a.disabled {
  -webkit-tap-highlight-color: transparent;
}

::placeholder {
  color: var(--placeholder-txt-color);
  opacity: 1;
}
::-webkit-input-placeholder {
  color: var(--placeholder-txt-color);
  opacity: 1;
}
:-moz-placeholder {
  color: var(--placeholder-txt-color);
  opacity: 1;
}
::-moz-placeholder {
  color: var(--placeholder-txt-color);
  opacity: 1;
}
:-ms-input-placeholder {
  color: var(--placeholder-txt-color);
  opacity: 1;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type='date']::-webkit-clear-button {
  display: none;
}
input[type='time']::-webkit-clear-button {
  display: none;
}

select:disabled,
input:disabled,
textarea:disabled {
  -webkit-text-fill-color: inherit;
  opacity: 1;
}

input[type='number']::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}