//
.so-header-search-select {
  background-color: var(--primary-color);
  border: 0 none;
  font-size: 0.8em;
  margin-right: 1rem;
  option {
    background-color: #fff;
    padding: 0 0.4rem;
    position: relative;
    color: var(--base-txt-color);
  }
}

.img-grid {
  border-width: 1px 0px;
  border-style: solid;
  border-color: #cccccc;

  box-sizing: border-box;

  .img-grid-title {
    padding: 12px;
    gap: 10px;
    background: #f7f7f7;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    &.selected {
      background: #e9eff9;
    }
    > label {
      position: absolute;
      right: 10px;
    }
  }
}

.hidden-input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
}

.imgItem {
  position: relative;
  > button {
    position: absolute;
    top: -5px;
    right: 5px;
    z-index: 1;
  }
}

.collection-sidebar {
  min-width: 24rem;
  background-color: #eaf3fd;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-right: 4rem;

  .collapsible {
    margin-bottom: 1rem;

    .collapsible-trigger {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
      background: none;
      border: none;
      cursor: pointer;
      color: #333;
      transition: background-color 0.2s;

      .collapsible-status {
        border: 1px solid #e4e4e7;
        padding: 0.2rem 1rem;
        font-size: 1.3rem;
        font-weight: 600;
        background-color: #fbfcfe;
        border-radius: 4px;

        &.processing {
          color: rgb(72, 140, 230);
        }
        &.complete {
          color: rgb(106, 195, 64);
        }

        &.fail {
          color: rgb(244, 85, 74);
        }
      }

      &:hover {
        background-color: #e3f2fd;
      }

      .icon {
        font-size: 1rem;
      }
    }

    .collapsible-content {
      max-height: 30vh;
      overflow-y: auto;
      padding: 0;
      list-style: none;

      .sidebar-item {
        min-width: 20rem;
          cursor: pointer;
          padding: 1.75rem;
          margin-bottom: 1rem;
          border-radius: 0.375rem;
          border-bottom: 1px solid #e0e0e0;
          transition: background-color 0.2s;
      
          &:hover {
            background-color: #ffffff;
          }
      
          &--selected {
            background-color: #ffffff;
          }
      
          &--processing {
              background: linear-gradient(90deg, #b3e5fc, #e1f5fe);
              background-size: 200% 200%;
              animation: gradient-shift 3s ease infinite;
              border: 1px solid transparent;
              box-shadow: 0 0 8px rgba(179, 229, 252, 0.5);
          }
      
          .item-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.5rem;
      
            .status-badge {
              display: inline-block;
              padding: 0.25rem 0.5rem;
              border-radius: 0.25rem;
              font-size: 0.75rem;
              font-weight: 500;
            }
      
            .site-name {
              font-size: 1.2rem;
              font-weight: 700;
              color: #555;

              img {
                width: 20px;
                height: 20px;
              }
            }
          }
      
          .item-footer {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;


            
             
            .method-badge {
              height: 100%;

              max-width: 8.4rem;
              margin-right: 0.5rem;
              padding: 0.2rem 1rem;
              line-height: 2rem;
              border: 1px solid #007bff;
              border-radius: 1.2rem;
              font-weight: 700;
              font-size: 1.4rem;
              color: #007bff;

              &.method {
                  border: 1px solid #dfe6f0;
                  background-color: #ffffff;
                  color: #6b6b6b;
                }
              
                &.count {
                  background-color: #f1f1f1e1;
                  color: #5f5f5fad;
              
                  &.success {
                    background-color: #cbecd0e1;
                    color: #245032e0;
                  }
              
                  &.fail {
                    background-color: #f7dedde1;
                    color: #914040e0;
                  }
                }
            }

            .reg-date {
              font-size: 1.2rem;
              font-weight: 700;
              color: #555;
            }
          }
        }
      
        @keyframes gradient-shift {
          0% {
            background-position: 0% 50%;
          }
      
          50% {
            background-position: 100% 50%;
          }
      
          100% {
            background-position: 0% 50%;
          }
        }
    }
  }

  .status-gray {
    background-color: #f0f0f0;
    color: #666;
  }

  .status-green {
    background-color: #d4edda;
    color: #155724;
  }

  .status-purple {
    background-color: #e8daef;
    color: #6c3483;
  }

  .status-default {
    background-color: #f8f9fa;
    color: #6c757d;
  }
}

.collect-product-list {
    padding: 0;

  .filter-bar {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;

    .result {
      display: flex;
      gap: 16px;
    }

    .filter-select {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  .product-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .product-card {
        display: flex;
        /* 플렉스 박스를 활성화 */
        flex-direction: column;
        /* 세로 방향으로 정렬 */
        flex: 1 1 calc(20% - 16px);
        max-width: calc(20% - 16px);
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 10px;
        overflow: hidden;

        &.category-valid {
            // border: 2px solid rgb(253 230 138);
            position: relative;
        }

        .label {
          position: absolute;
          display: flex;
          align-items: center;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          font-size: 1.2rem;
          color: #000;
          font-weight: bold;
          white-space: nowrap;

          &.category {
            top: 5px;
            right: 5px;
          }

        }
        
      .label2 {
        position: absolute;
        display: flex;
        align-items: center;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        font-size: 1.2rem;
        color: #000;
        font-weight: bold;
        white-space: nowrap;


        &.saved {
          top: 5px;
          right: 5px;
        }
      }

        &.list {
          flex: 1 1 calc(13% - 16px);
            max-width: calc(13% - 16px);
        }

        &.save-processing {
          opacity: 0.5;
        }
    
        .image-placeholder {
          width: 100%;
          height: 120px;
          background-color: #fff;
          position: relative;
          align-items: center;
          cursor: pointer;
          justify-content: center;
          margin-bottom: 8px;
    
          &::before {
            content: '';
            display: block;
          }
    
          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
    
          .check-box {
            position: absolute;
            top: 8px;
            left: 8px;
          }
        }
    
        .product-info {
          margin: 0 10px 10px 10px;
          flex: 1;
          /* 남은 공간을 차지하여 p를 하단으로 밀어냄 */
          display: flex;
          flex-direction: column;
    
          h5 {
            font-size: 1.4rem;
            font-weight: 500;
            color: #555;
          }
    
    
          .ellipsis-2 {
            margin-bottom: 12px;
          }
            /* h5와 a의 공간을 확보 */
          a {
            font-size: 1.4rem;
            text-decoration: underline;
            line-height: 1.25rem;
            font-weight: 500;
            color: var(--strong-txt-color);
            position: relative;
            /* 툴팁 위치 설정을 위한 상대 위치 */
          }
          
    
          p {
            margin-top: auto;
            /* 하단으로 고정 */
            font-size: 16px;
            font-weight: 700;
            color: #555;
          }
        }
      }
  }

  .load-more-button {
    margin: 16px auto;
    display: block;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.pointer {
  cursor: pointer;
}

.cropper-crop {
  cursor: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.2444 11.3203H22.8718M11.5581 0.006558V22.634' stroke='%23C1EDCC' stroke-width='2'/%3E%3C/svg%3E") 12.5 12.5,
      crosshair !important;
}