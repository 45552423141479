.tab-container .close-tab {
  display: block;
  margin-left: 1rem;
  width: 1.6rem;
  height: 1.6rem;
  background: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.50537 11.4953L8.0007 8L11.496 11.4953M11.496 4.50467L8.00004 8L4.50537 4.50467' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
    center center / 100% no-repeat;
  cursor: pointer;
  font-size: 0;
}

.tab-container {
  position: relative;
  width: 100%;
  background-color: #e9eff9;
  padding-left: 5.2rem;
  .move-wrap {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 0;
    right: 0;
    width: 10rem;
    background: linear-gradient(270deg, #e9eff9 76%, rgba(233, 239, 249, 0) 100%);
    .button {
      margin: 0;
      padding: 1.4rem 0.8rem 1.4rem 1.6rem;
      height: 4.8rem;
      &:hover {
        box-shadow: none;
      }
      + button {
        padding: 1.4rem 1.6rem 1.4rem 0.8rem;
      }
      i {
        width: 2rem;
        height: 2rem;
        vertical-align: initial;
      }
    }
  }
}
.tab-container ul {
  display: flex;
  width: calc(100% - 8rem);
  flex-wrap: nowrap;
  background-color: #e9eff9;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-scrollbar: no-button;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.tab {
  display: flex;
  align-items: center;
  height: 4.8rem;
  padding: 1.5rem 2.4rem 1.3rem 2.4rem;
  cursor: pointer;
  background-color: var(--bg-color);
  background-color: #e9eff9;
  font-size: 1.4rem;
  line-height: 2rem;
  text-wrap: nowrap;
}
.tab:hover,
.tab.active {
  background-color: var(--white-color);
  color: var(--primary-color2);
  font-weight: 700;
  border-radius: 1.2rem 1.2rem 0 0;
  .close-tab {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.50534 11.4953L8.00067 8L11.496 11.4953M11.496 4.50467L8.00001 8L4.50534 4.50467' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  a {
    line-height: 5rem;
  }
}

.tab-content {
  display: none;
  min-width: 100%;
  max-width: 1440px;
  height: 100%;
  padding: 4rem 4.8rem 6.4rem;
  background-color: white;
  iframe body {
    margin: 0;
  }
}
#dashboard {
  border-top-left-radius: 0 !important;
}

.tab-content.active {
  display: block;

  .product-detail-controller {
    display: flex;
    gap: 10px;
    margin-top: -2rem;
    padding: 10px;
    justify-content: end;
  }
}
.folding-list li .folding-panel {
  display: none;
}
.folding-list li.open .folding-panel {
  display: block;
}
.tab-line-menu {
  width: 100%;
  border-bottom: 1px solid var(--primary-color2);
  &.ty2 {
    border-bottom: none;
    .tab-list {
      .tab {
        width: 12.8rem;
        height: 4.8rem;
        margin-right: 0.8rem;
        background-color: var(--white-color);
        border-radius: 6.4rem;
        border: 1px solid var(--line-color01);
        a {
          padding: 0;
          line-height: 4.8rem;
          color: var(--sub-txt-color);
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background-color: var(--primary-color2);
          border: none;
          > a {
            color: var(--white-color);
          }
        }
      }
    }
  }
  .tab-list {
    display: flex;
    .tab {
      min-width: 13.6rem;
      height: 4.4rem;
      padding: 0 1.6rem;
      background-color: var(--disabled-bg-color);
      border: 1px solid var(--bg-color-blue02);
      border-radius: 0.6rem 0.6rem 0 0;
      
      &.active {
        background-color: var(--white-color);
        border: 1px solid var(--primary-color2);
        border-bottom-width: 0;
        > a,
        button {
          color: var(--primary-color2);
          &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: -1.6rem;
            width: calc(100% + 3.2rem);
            height: 1px;
            background-color: var(--white-color);
          }
        }
      }
      > a,
      button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: var(--sub-txt-color2);
        font-weight: 600;
        text-align: center;
      }
    }
  }
}
.tab-panel {
  display: none;
  &.active {
    display: block;
  }
}
