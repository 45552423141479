.container {
  position: relative;
  display: flex;
}
.contents {
  width: 100%;
  // width: calc(100% - 240px);
  // min-height: calc(100vh - 80px);
  height: 100%;
  transition: width 0.4s;
  // overflow: hidden;
  // &.close {
  //   width: calc(100% - 80px);
  // }
}

.translate-box {
  width: 100%;
  padding: 3.2rem;
  border: 1px solid var(--line-color02);
  border-radius: 1.2rem;
}

.favorite {
  margin-top: -0.1rem;
  margin-left: 0.8rem;
  width: 2.4rem;
  height: 2.4rem;
  background-image: url(/images/common/favorite.png);
  &.checked {
    background-image: url(/images/common/favorite_clicked.png);
  }
}
.tit-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .h1 {
    display: flex;
    align-items: center;
  }
  .tooltip,
  .favorite {
    font-size: 0;
    display: inline-block;
    cursor: pointer;
  }
  .path {
    display: flex;
    align-items: center;
    > a {
      position: relative;
      display: block;
      margin-right: 2.4rem;
      font-size: 1.4rem;
      line-height: 2rem;
      &.home {
        width: 2rem;
        font-size: 0;
        background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.875 10L9.33667 2.5375C9.70333 2.17166 10.2967 2.17166 10.6625 2.5375L18.125 10M3.75 8.125V16.5625C3.75 17.08 4.17 17.5 4.6875 17.5H8.125V13.4375C8.125 12.92 8.545 12.5 9.0625 12.5H10.9375C11.455 12.5 11.875 12.92 11.875 13.4375V17.5H15.3125C15.83 17.5 16.25 17.08 16.25 16.5625V8.125M6.875 17.5H13.75' stroke='%23666666' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          center center / 100% no-repeat;
      }
      &:last-child {
        margin-right: 0;
        font-weight: 700;
        &::after {
          content: none;
        }
      }
    }
    > a:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17L14.7879 12.2121C14.905 12.095 14.905 11.905 14.7879 11.7879L10 7' stroke='%23999999' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
}
.rounded {
  display: block;
  height: 2.6rem;
  padding: 0.4rem 1.2rem;
  background-color: var(--disabled-bg-color);
  border: 1px solid var(--line-color02);
  border-radius: 1.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--sub-txt-color);
}
.consulting {
  position: fixed;
  z-index: 999;
  bottom: 1.6rem;
  right: 0;
  padding: 1.2rem 2.4rem 1.2rem 5rem;
  background: var(--bg-color-green) url(/images/common/head-chat.png) left 1.6rem center / 3.2rem 3.2rem no-repeat;
  border-radius: 2.4rem 0 0 2.4rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--white-color);
}
.fixed-wrap {
  position: fixed;
  z-index: 10;
  bottom: 4rem;
  right: 4rem;
  .fixed-btn {
    display: block;
    width: 5.6rem;
    height: 5.6rem;
    margin-right: 0;
    border-radius: 50%;
    &.chat {
      background: url(/images/common/head-chat3x.png) center / 5.6rem no-repeat;
    }
    &.go-top {
      margin-top: 1.6rem;
      background: var(--strong-txt-color)
        url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0004 4.45581C15.6592 4.45581 15.3183 4.58837 15.0577 4.84904L7.516 12.3907C6.99467 12.9107 6.99467 13.7548 7.516 14.2761L7.63058 14.3907C8.15058 14.912 8.99467 14.912 9.516 14.3907L14.667 9.23967V26.6667C14.667 27.4027 15.2644 28.0001 16.0004 28.0001C16.7364 28.0001 17.3337 27.4027 17.3337 26.6667V9.23967L22.4847 14.3907C23.0047 14.912 23.8488 14.912 24.3702 14.3907L24.4847 14.2761C25.0061 13.7561 25.0061 12.912 24.4847 12.3907L16.9431 4.84904C16.6831 4.58837 16.3415 4.45581 16.0004 4.45581Z' fill='white'/%3E%3C/svg%3E%0A")
        center/ 3.2rem no-repeat;
    }
  }
  &.mo {
    bottom: 2.4rem;
    right: 2.4rem;
    .fixed-btn {
      width: 4rem;
      height: 4rem;
      background-size: 100%;
    }
  }
}
.index-inner {
  padding: 4rem;
  .txt-wrap {
    display: flex;
    align-items: baseline;
  }
  h3 {
    margin-right: 1rem;
    font-size: 3.2rem;
    color: var(--base-txt-color);
  }
  span {
    color: var(--sub-txt-color2);
  }
  .table td {
    text-align: center;
    color: var(--sub-txt-color);
  }
}
.login {
  width: 100%;
  min-width: 1680px;
  background: #edf4fe;
  &.main {
    height: 100%;
  }
  &.mo {
    min-width: 36rem;
    .login-contents {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        width: 14.6rem;
        height: 3rem;
        background: url(/images/common/marketwiz-login-logox3.png) center / 100% no-repeat;
      }
    }
    .input {
      border-color: var(--line-color02);
    }
    .log-art {
      width: 25.6rem;
      height: 24.6rem;
      margin: 2.4rem auto 0;
      background: url(/images/common/login-art-mo.png) center/100% no-repeat;
    }
    .login-contents {
      .btn-log {
        width: 31.2rem;
      }
      .component,
      .components {
        display: block;
        position: static;
        transform: translate(0, 0);
        padding: 0;
      }
      .components {
        margin-top: 9.8rem;
      }
    }
  }
  .login-contents {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 2.4rem;
    h2 {
      margin-bottom: 4rem;
      font-size: 3.2rem;
      line-height: 3.2rem;
    }
    .logo {
      display: block;
      width: 32rem;
      height: 6.1rem;
      background: url(/images/common/marketwiz-login-logo.png);
      font-size: 0;
      margin: 0 auto;
    }
    .btn-log {
      width: 34rem;
    }
  }
  .login-design {
    position: relative;
    width: 100%;
    .component {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // height: 83rem;
      padding-top: 10.5rem;
    }
  }
  // .logo {
  //   display: block;
  //   width: 32rem;
  //   height: 6.2rem;
  //   margin: 0 auto;
  //   font-size: 0;
  //   background: url(/images/common/marketwiz-login-logo.png) left center / 100% no-repeat;
  //   &.ty2 {
  //     position: absolute;
  //     left: 6rem;
  //     top: 4rem;
  //     width: 20rem;
  //     height: 4rem;
  //     > a {
  //       display: block;
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
  .log-art {
    width: 65.9rem;
    height: 48.1rem;
    margin-top: 12rem;
    background: url(/images/common/login-art.png) no-repeat;
  }
  .login-ui {
    flex-shrink: 0;
    position: relative;
    width: 48rem;
    background-color: var(--white-color);
    border-radius: 2rem;
    text-align: center;
  }
  .components {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  // h1 {
  //   margin-bottom: 4rem;
  //   font-size: 3.2rem;
  //   line-height: 3.2rem;
  // }
  .join-wrap {
    position: relative;
    padding-bottom: 6.4rem;
  }
  .join-form {
    margin: 0 auto;
    padding-top: 7.2rem;
    width: 75.2rem;
    h2 {
      font-size: 3.2rem;
      line-height: 1;
      font-weight: 700;
      text-align: center;
    }
    .form-box {
      margin-top: 3.2rem;

      padding: 5.6rem;
      background-color: var(--white-color);
      border-radius: 2rem;
      box-shadow: 12px 12px 20px -10px rgba(0, 0, 0, 0.08), 0px -7px 10px -6px rgba(0, 0, 0, 0.04);
      .btn-log {
        margin-bottom: 0;
      }
      .form-tit {
        font-size: 2.2rem;
        line-height: 3.2rem;
        font-weight: 700;
        &.has-border {
          margin-top: 4rem;
          padding-top: 4rem;
          border-top: 1px solid var(--line-color03);
        }
        span {
          margin-left: 0.8rem;
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 400;
          color: var(--sub-txt-color2);
        }
      }
      dt {
        font-size: 1.8rem;
        line-height: 2.8rem;
        font-weight: 700;
        margin-bottom: 0.8rem;
        margin-top: 1.6rem;
        &:first-child {
          margin-top: 0;
        }
      }
      .input-box {
        .btn-checked {
          display: none;
          position: absolute;
          top: 50%;
          right: 1.3rem;
          transform: translateY(-50%);
        }
      }
      .checked {
        position: relative;
        .input {
          border: 1px solid var(--primary-color2);
        }
        .btn-checked {
          display: block;
        }
      }
    }
    .checkbox-wrap {
      margin: 4.8rem 0 3.2rem;
    }
    .bg-primary {
      width: 100%;
      height: 5.6rem;
      font-size: 1.8rem;
      line-height: 2.8rem;
      text-align: center;
    }
  }
  .login-complete {
    position: relative;
    padding: 7.2rem 0 12rem;
    > p {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 1;
      text-align: center;
    }
    .button {
      width: 32rem;
      height: 5.6rem;
      font-size: 1.8rem;
      text-align: center;
    }
    .solution-signup {
      width: 93.8rem;
      height: 50.2rem;
      margin: 6rem auto 0;
      text-align: center;
      background: url(/images/common/solution-sign-up.png);
      h3 {
        padding-top: 9rem;
        font-size: 4rem;
        line-height: 4.8rem;
        & + p {
          margin-top: 1rem;
          font-size: 2.2rem;
          line-height: 3.2rem;
          font-weight: 500;
          color: var(--strong-txt-color);
        }
      }
      .solution-summary {
        display: flex;
        justify-content: space-between;
        div {
          position: relative;
          width: 24rem;
          height: 24rem;
          padding: 5.8rem 4.6rem;
          background-color: var(--bg-color-blue02);
          border-radius: 1.6rem;
          text-align: center;
          img {
            width: 8rem;
            height: 8rem;
          }
          p {
            margin-top: 2.4rem;
            font-size: 1.6rem;
            font-weight: 700;
            line-height: 2rem;
            color: var(--primary-color2);
          }
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 1.2rem;
            height: 1.2rem;
            right: -2.7rem;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.63804 12V7.36196H0V4.63804H4.63804V0H7.33742V4.63804H12V7.36196H7.33742V12H4.63804Z' fill='%2342444E'/%3E%3C/svg%3E%0A");
          }
          &:last-child::after {
            content: none;
          }
        }
      }
    }
    .btn-center {
      margin-top: 8rem;
      text-align: center;
    }
  }
}
.btn-log {
  position: relative;
  display: block;
  width: 20.8rem;
  height: 5.6rem;
  margin-bottom: 1.2rem;
  border-radius: 0.8rem;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 5.6rem;
  text-align: center;
  .btn-checked {
    position: absolute;
    top: 50%;
    right: 1.6rem;
    transform: translateY(-50%);
  }
  &.naver {
    background: #00c73c url(/images/common/naver.png) left 1rem center / 4.8rem 4.8rem no-repeat;
    color: var(--white-color);
  }
  &.kakao {
    margin-right: 0;
    background: #fae100
      url("data:image/svg+xml,%0A%3Csvg width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 0.5C6.09621 0.5 0.5 4.92952 0.5 10.3967C0.5 13.954 2.86971 17.0717 6.429 18.8161C6.16701 19.7923 5.48253 22.3568 5.34564 22.9052C5.1757 23.5859 5.59347 23.5765 5.86962 23.3945C6.0844 23.2503 9.29437 21.0639 10.6799 20.1208C11.4328 20.2319 12.2069 20.291 13 20.291C19.9038 20.291 25.5 15.8615 25.5 10.3967C25.5 4.93188 19.9038 0.5 13 0.5Z' fill='%23371D1E'/%3E%3C/svg%3E%0A")
      left 2.25rem center / 2.5rem 2.3rem no-repeat;
  }
  &.google {
    background: var(--white-color) url(/images/common/google.png) left 1.1rem center / 4.8rem 4.8rem no-repeat;
    border: 1px solid var(--line-color02);
  }
  &.market {
    min-width: auto;
    background: var(--primary-color2) url(/images/common/logo-only.png) left 1.1rem center / 4.8rem 4.8rem no-repeat;
    color: var(--white-color);
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.tooltip-wrap {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  &:hover {
    .tooltip-txt {
      display: block;
    }
  }
}
.tooltip {
  display: inline-block;
  margin-left: 0.4rem;
  margin-right: 0;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z' fill='%23DBDBDB'/%3E%3Cpath d='M7.91663 8.0268C7.91663 5.10219 12.0833 5.10219 12.0833 8.0268C12.0833 10.1158 9.86468 9.698 9.86468 12.2048M9.86468 14.1667L9.87117 14.1575' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.tooltip-txt {
  display: none;
  position: absolute;
  top: -0.3rem;
  left: calc(100% + 0.8rem);
  padding: 0.6rem 1rem;
  background: var(--bg-color);
  border-radius: 0.4rem;
  box-sizing: border-box;
  color: var(--white-color);
  font-size: 1.2rem;
  font-weight: 400;
  white-space: nowrap;
  line-height: 1.4rem;
  &::before {
    content: '';
    position: absolute;
    left: -0.7rem;
    top: 0.5rem;
    width: 1.6rem;
    height: 1.6rem;
    background: url("data:image/svg+xml,%0A%3Csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.499999 7.86602C-0.166668 7.48112 -0.166667 6.51887 0.5 6.13397L9.5 0.937821C10.1667 0.552921 11 1.03405 11 1.80385L11 12.1962C11 12.966 10.1667 13.4471 9.5 13.0622L0.499999 7.86602Z' fill='%23273043'/%3E%3C/svg%3E%0A")
      center / 100% no-repeat;
  }
  p {
    position: relative;
    margin-bottom: 0.4rem;
    &:last-child {
      margin-bottom: 0;
    }
    &.dot {
      padding-left: 1rem;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0.2rem;
        transform: translateY(-50%);
        width: 0.2rem;
        height: 0.2rem;
        border-radius: 50%;
        background-color: var(--white-color);
      }
    }
  }
}
.ico-new {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.4rem;
  vertical-align: text-top;
  background: url("data:image/svg+xml,%0A%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z' fill='%23FF3528'/%3E%3Cpath d='M11.625 5.625V12.375H10.6015L7.575 7.76001H7.52206V12.375H6.375V5.625H7.41618L10.425 10.24H10.4868V5.625H11.625Z' fill='white'/%3E%3C/svg%3E%0A")
    center center / 1.6rem 1.6rem no-repeat;
  font-size: 0;
}
.classify-wrap {
  position: relative;
  display: flex;
  width: 6.1rem;
  height: 3.2rem;
  margin-left: 0.4rem;
  border: 1px solid var(--line-color01);
  border-radius: 0.6rem;
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1px;
    height: 100%;
    background-color: var(--bg-color-blue01);
  }
  .classify {
    width: 3rem;
    line-height: 3.2rem;
    font-size: 0;
    background-position: center;
    background-size: 2.4rem 2.4rem;
    background-repeat: no-repeat;
    &.image {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 6H11V11H6V6Z' fill='%23DDDDDD'/%3E%3Cpath d='M6 13H11V18H6V13Z' fill='%23DDDDDD'/%3E%3Cpath d='M13 6H18V11H13V6Z' fill='%23DDDDDD'/%3E%3Cpath d='M13 13H18V18H13V13Z' fill='%23DDDDDD'/%3E%3C/svg%3E%0A");
      &:hover,
      &.active {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 6H11V11H6V6Z' fill='%23055DB5'/%3E%3Cpath d='M6 13H11V18H6V13Z' fill='%23055DB5'/%3E%3Cpath d='M13 6H18V11H13V6Z' fill='%23055DB5'/%3E%3Cpath d='M13 13H18V18H13V13Z' fill='%23055DB5'/%3E%3C/svg%3E%0A");
      }
    }
    &.list {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='white'/%3E%3Crect x='5' y='6' width='14' height='2' fill='%23DDDDDD'/%3E%3Crect x='5' y='11' width='14' height='2' fill='%23DDDDDD'/%3E%3Crect x='5' y='16' width='14' height='2' fill='%23DDDDDD'/%3E%3C/svg%3E%0A");
      &:hover,
      &.active {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' fill='white'/%3E%3Crect x='5' y='6' width='14' height='2' fill='%23055DB5'/%3E%3Crect x='5' y='11' width='14' height='2' fill='%23055DB5'/%3E%3Crect x='5' y='16' width='14' height='2' fill='%23055DB5'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
.require {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: auto;
  height: 1.6rem;
  &::after {
    content: '*';
    position: absolute;
    right: -1rem;
    color: #ff0000;
  }
}
.has-claim {
  margin-right: 0.8rem;
  padding-left: 2.4rem;
  background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z' fill='%23FF1C37'/%3E%3Cpath d='M12.6348 7L12.546 13.6016H11.4796L11.3907 7H12.6348ZM12.0128 16.2676C11.5684 16.2676 11.1876 15.8994 11.2003 15.4424C11.1876 14.998 11.5684 14.6299 12.0128 14.6299C12.4571 14.6299 12.8253 14.998 12.8253 15.4424C12.8253 15.8994 12.4571 16.2676 12.0128 16.2676Z' fill='white' stroke='white' stroke-width='0.5'/%3E%3C/svg%3E%0A")
    left center / 2.4rem 2.4rem no-repeat;
  color: var(--alert-txt-color);
  font-weight: 600;
}
.detail-view {
  width: 2.4rem;
  height: 2.4rem;
  background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='24' height='24' rx='12' fill='%23E5E9F2'/%3E%3Cpath d='M12.8109 14.8764C12.4117 15.4295 11.5883 15.4295 11.1891 14.8764L8.8139 11.5852C8.3366 10.9238 8.80917 10 9.62478 10L14.3752 10C15.1908 10 15.6634 10.9238 15.1861 11.5852L12.8109 14.8764Z' fill='%2342444E'/%3E%3C/svg%3E%0A")
    center center / 2.4rem 2.4rem no-repeat;
  font-size: 0;
  &.ty2 {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8109 14.8764C12.4117 15.4295 11.5883 15.4295 11.1891 14.8764L8.8139 11.5852C8.3366 10.9238 8.80917 10 9.62478 10L14.3752 10C15.1908 10 15.6634 10.9238 15.1861 11.5852L12.8109 14.8764Z' fill='%2342444E'/%3E%3C/svg%3E%0A");
  }
  &.open {
    transform: rotate(180deg);
  }
}
.middot-list {
  li {
    position: relative;
    padding-left: 1.4rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--strong-txt-color);
    &::before {
      content: '';
      position: absolute;
      top: 0.9rem;
      left: 0.5rem;
      width: 0.2rem;
      height: 0.2rem;
      background-color: var(--sub-txt-color);
      border-radius: 50%;
    }
  }
}
.td-notice {
  position: relative;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--sub-txt-color2);
  &::before {
    content: '※';
    position: absolute;
    left: 0;
    top: 0;
  }
  &.ty2::before {
    content: '*';
  }
  &.ty3 {
    padding-left: 0;
    &::before {
      content: none;
    }
  }
}

.td-notice-none {
  position: relative;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--sub-txt-color2);
}

.record-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  ul {
    width: 27.6rem;
    height: 13.2rem;
    border-top: 1px solid var(--line-color01);
    border-bottom: 1px solid var(--line-color01);
  }
  li {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1.2rem;
    border-bottom: 1px solid var(--line-color03);
    &:last-child {
      border-bottom: none;
    }
  }
}
.service-notice {
  padding: 1.6rem;
  background-color: var(--disabled-bg-color);
  border-top: 1px solid var(--line-color01);
  border-bottom: 1px solid var(--line-color01);
  font-size: 1.3rem;
  line-height: 2rem;
  color: var(--strong-txt-color);
  dl {
    display: flex;
    + dl {
      margin-top: 1.6rem;
      padding-top: 1.6rem;
      border-top: 1px solid var(--line-color02);
    }
    dt {
      width: 11.2rem;
    }
  }
  li {
    padding: 0.6rem;
    border-bottom: 1px solid var(--line-color02);
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .dot {
    position: relative;
    color: var(--strong-txt-color);
    &::before {
      content: '';
      position: absolute;
      left: 0.6rem;
      top: 50%;
      transform: translateY(-50%);
      width: 0.2rem;
      height: 0.2rem;
      background-color: var(--sub-txt-color);
      border-radius: 50%;
    }
  }
  p {
    padding-left: 1.4rem;
    color: var(--sub-txt-color);
  }
}

.badge {
  display: inline-block;
  padding: 0.3rem 0.8rem;
  border-radius: 3px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-align: center;
  font-weight: 600;
  &.blue {
    color: var(--primary-color2);
    background-color: #e3edff;
  }

  &.red {
    background-color: #FEF1F1;
    color: #F4554A;
  }

  &.purple {
    color: #423895;
    background-color: #eeebff;
  }
  &.green {
    color: #109b6e;
    background-color: #d2f0ec;
  }
  &.orange {
    color: #f68827;
    background-color: #f8ebde;
  }
  &.gray {
    color: var(--strong-txt-color);
    background-color: var(--disabled-bg-color);
  }

  &.gray2 {
    color: var(--strong-txt-color);
    background-color: #f4f4f5;
  }
}
.img-box {
  position: relative;
  display: block;
  &.selected {
    border: 2px solid var(--primary-color2);
  }
  &.obf-contain {
    img {
      object-fit: contain;
    }
  }
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  span {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 2.4rem;
    height: 2.4rem;
    font-size: 0;
    background: rgba($color: #000000, $alpha: 0.5)
      url("data:image/svg+xml,%0A%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 13L9.53548 9.53548M9.53548 9.53548C10.4732 8.59781 10.9999 7.32605 10.9999 5.99997C10.9999 4.6739 10.4732 3.40213 9.53548 2.46446C8.59781 1.52678 7.32605 1 5.99997 1C4.6739 1 3.40213 1.52678 2.46446 2.46446C1.52678 3.40213 1 4.6739 1 5.99997C1 7.32605 1.52678 8.59781 2.46446 9.53548C3.40213 10.4732 4.6739 10.9999 5.99997 10.9999C7.32605 10.9999 8.59781 10.4732 9.53548 9.53548Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      center center / 1.4rem 1.4rem no-repeat;
  }
}
.file-name {
  margin-left: 2.4rem;
  color: var(--strong-txt-color);
  i {
    margin-left: 0.4rem;
  }
}
.load-screen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .load-contents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
    font-size: 1em;
    color: var(--white-color);
    .loading-wheel {
      margin: 3.2rem auto 0;
    }
    .load-spinner {
      width: 1.3em;
      height: 1.3em;
      animation: rotate 2s linear infinite;
      margin-top: 1em;
      & .path {
        stroke: var(--white-color);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
      }
    }
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }
}
.progress-box {
  position: relative;
  .load-contents {
    p {
      color: var(--strong-txt-color);
    }
  }
  .load-screen {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    .load-contents {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-weight: 600;
      color: var(--white-color);
      .loading-wheel {
        margin: 3.2rem auto 0;
      }
      .load-spinner {
        width: 6.8rem;
        height: 6.8rem;
        animation: rotate 2s linear infinite;
        margin-top: 1.6rem;
        & .path {
          stroke: var(--primary-color2);
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }
      }
      @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 150;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -35;
        }
        100% {
          stroke-dasharray: 90, 150;
          stroke-dashoffset: -124;
        }
      }
    }
  }
}
.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem auto 0;
  .img-wrap {
    display: flex;
    .img-slide {
      display: inline-block;
      margin-left: 1.6rem;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .button-prev,
  .button-next {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
  }
  .button-prev {
    margin-right: 1.6rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 30L15 20L25 10' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  .button-next {
    margin-left: 1.6rem;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 10L25 20L15 30' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
.folding-table {
  position: relative;
  border-top: 1px solid var(--line-color01);
  border-bottom: 1px solid var(--line-color01);
  overflow-x: hidden;
  &.cs-center {
    .ft-list::after {
      content: none;
    }
    .depth-1 {
      > li {
        display: flex;
        align-items: center;
        padding: 0 2rem;
        flex-wrap: wrap;
        border-top: 1px solid var(--line-color02);
        .num {
          width: 7.2rem;
        }
        .ft-list {
          width: calc(100% - 12rem);
          height: 6.4rem;
          .ico-circle-up {
            display: none;
          }
          .ico-circle-down {
            display: block;
          }
          &.open {
            .ico-circle-up {
              display: block;
            }
            .ico-circle-down {
              display: none;
            }
          }
        }
        &::before {
          content: none;
        }
      }
      .depth-2 {
        > li {
          padding-left: 7.2rem;
          > div {
            position: relative;
            display: flex;
            p {
              padding: 1.2rem;
              font-size: 1.4rem;
              line-height: 2rem;
            }
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: -2rem;
              height: 0.1rem;
              background-color: var(--line-color02);
            }
          }
          &::before {
            content: none;
          }
        }
      }
    }
    .num {
      font-size: 1.4rem;
      line-height: 2rem;
    }
    .ft-head {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.8re;
    }
    .question,
    .answer {
      flex-shrink: 0;
      width: 4.8rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.8rem;
    }
    .answer {
      padding-top: 1.2rem;
      color: var(--primary-color2);
    }
  }
  .head-tit {
    padding: 0.6rem 1.2rem;
    height: 4.4rem;
    background-color: var(--disabled-bg-color);
    border-bottom: 1px solid var(--line-color03);
    span {
      margin-right: 0.8rem;
      font-size: 1.5rem;
      font-weight: 700;
      vertical-align: middle;
    }
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -1px;
    right: 100%;
    width: 1.6rem;
    height: 1px;
    background-color: var(--white-color);
  }
  .ft-checkbox {
    position: relative;
    margin-left: 1.2rem;
    padding-left: 1.2rem;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -10px;
      bottom: -10px;
      width: 1px;
      background-color: var(--line-color03);
    }
  }
  .ft-list.tr-hover {
    background-color: #e9eff9;
  }
  .depth-1 {
    li:before {
      left: 4.8rem;
    }
    > li:last-child {
      .ft-list::after {
        content: none;
      }
    }
  }
  .depth-2 {
    display: none;
    li:before {
      left: 9.6rem;
    }
    .ft-list {
      margin-left: 4.8rem;
      &:after {
        left: -4.8rem;
      }
    }
  }
  .depth-3 {
    display: none;
    li:before {
      left: 14.4rem;
    }
    .ft-list {
      margin-left: 9.6rem;
      &:after {
        left: -9.6rem;
      }
    }
  }
  .depth-4 {
    display: none;
    li:before {
      left: 19.2rem;
    }
    .ft-list {
      margin-left: 14.4rem;
      &:after {
        left: -14.4rem;
      }
    }
  }
  .depth-5 {
    display: none;
    li:before {
      left: 24rem;
    }
    .ft-list {
      margin-left: 19.2rem;
      &:after {
        left: -19.2rem;
      }
    }
  }
  .depth-6 {
    display: none;
    li:before {
      left: 28.8rem;
    }
    .ft-list {
      margin-left: 24rem;
      &:after {
        left: -24rem;
      }
    }
  }
  ul {
    li {
      .ft-checkbox {
        flex-shrink: 0;
        width: 10.4rem;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid var(--line-color03);
        &::before {
          top: 0;
          bottom: 0;
        }
        .check-box {
          line-height: 4.4rem;
          input {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .checkbox + label {
            padding: 0;
          }
          .checkbox + label:before {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      position: relative;
      &::before {
        content: '';
        z-index: 1;
        position: absolute;
        width: 1px;
        top: 0;
        bottom: 0;
        background-color: var(--line-color03);
      }
      &.open {
        .depth-2 {
          display: block;
          .open {
            .depth-3 {
              display: block;
              .open {
                .depth-4 {
                  display: block;
                  .open {
                    .depth-5 {
                      display: block;
                      .open {
                        .depth-6 {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ft-list {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    &.open {
      .ico-plus {
        display: none;
      }
      .ico-minus {
        display: block;
      }
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: var(--line-color03);
    }
    i {
      margin: 0 1.2rem;
      &.ico-plus {
        display: block;
      }
      &.ico-minus {
        display: none;
      }
    }
    .ft-head {
      width: 100%;
      min-width: 73.6rem;
      padding: 1.2rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
.bottm-border {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: var(--line-color01);
  }
}
.alert-msg {
  margin-bottom: 1.6rem;
  font-size: 1.7rem;
  line-height: 2.4rem;
  font-weight: 600;
  color: var(--alert-txt-color);
  text-align: center;
}
.market.mo {
  position: relative;
  margin: 0 auto;
  min-width: 36rem;
  .contents-wrap {
    padding: 0 1.6rem;
  }
  &.has-bg {
    padding-bottom: 2.4rem;
    background: #edf4fe;
  }
  &.login {
    width: 100%;
    height: 100%;
    background: #edf4fe;
  }
  .lnb {
    &.mo {
      display: none;
      &.active {
        display: block;
      }
      &.guide {
        display: block;
        position: static;
        .lnb-inner {
          position: relative;
          height: 85rem;
          min-height: auto;
        }
      }
    }
    position: absolute;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    .lnb-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -2.4rem 1.6rem;
      padding: 0 2.4rem 1.7rem;
      border-bottom: 1px solid var(--line-color02);
    }
    .lnb-inner {
      position: fixed;
      min-width: 26.8rem;
      padding: 1.7rem 2.4rem 0;
      i {
        margin-right: 0;
      }
      .img-box {
        margin: 0 0.8rem 0 0;
      }
      .close-lnb {
        top: 1.6rem;
        left: calc(100% + 1.6rem);
        width: 4rem;
        height: 4rem;
        background: var(--white-color)
          url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75806 17.243L12.0011 12L17.2441 17.243M17.2441 6.757L12.0001 12L6.75806 6.757' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
          center / 2.4rem no-repeat;
        border-radius: 50%;
      }
    }
  }
  .folding-list {
    margin: 0 -2.4rem;
    padding: 0 2.4rem 1.6rem;
    border-bottom: 1px solid var(--line-color02);
    & > li:first-child {
      margin: 0;
      padding: 1.6rem 0;
      border: none;
    }
  }
  .folding-panel {
    li {
      padding: 1rem 3rem;
    }
  }
  .account-summary {
    margin-top: 3.6rem;
    p {
      margin-bottom: 1.6rem;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
    }
    dl {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--line-color02);
      font-size: 1.4rem;
      line-height: 2.4rem;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom-width: 0;
      }
      dt {
        font-weight: 500;
      }
      dd {
        font-weight: 600;
      }
    }
  }
}
.market.fo {
  .contents-wrap {
    position: relative;


  .header {
      display: flex;
      padding: 2rem;
    }

    &.has-bg {
      background-color: #edf4fe;
    }
  }
}
.join-wrap {
  margin: 0 auto;
}
.join-heading {
  padding-top: 7.2rem;
  text-align: center;
  h2 {
    margin-bottom: 1.6rem;
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
  p {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.8rem;
    color: var(--sub-txt-color);
  }
}
.join-process {
  display: flex;
  justify-content: space-between;
  width: 64rem;
  margin: 3.2rem auto 0;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  line-height: 3rem;
  color: var(--sub-txt-color2);
  > div {
    width: 13.6rem;
    &:last-child {
      .circle:after {
        content: none;
      }
    }
    &.active {
      .circle {
        background-color: var(--primary-color2);
        color: var(--white-color);
      }
      p {
        color: var(--primary-color2);
      }
    }
  }
  p {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
  }
  .circle {
    position: relative;
    width: 4.8rem;
    height: 4.8rem;
    margin: 0 auto 1.8rem;
    background-color: var(--bg-color-gray);
    border-radius: 50%;
    line-height: 4.8rem;
    &::after {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      width: 12rem;
      height: 0.1rem;
      background-color: var(--bg-color-gray);
    }
  }
}
.join-form {
  .join {
    width: 75.2rem;
    margin: 4rem auto 0;
    padding: 5.6rem;
    background-color: var(--white-color);
    border-radius: 2rem;
    opacity: 0.5;
    box-shadow: 12px 12px 20px -10px #00000014;
    h3 {
      font-size: 2.2rem;
      line-height: 3.2rem;
      span {
        margin-left: 0.8rem;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: var(--sub-txt-color2);
      }
    }
    &.active {
      opacity: 1;
    }
    &:first-child {
      h4 {
        margin-top: 1.6rem;
      }
    }
    .result-box {
      height: 4.8rem;
      padding: 1.2rem 1.6rem;
      background: var(--disabled-bg-color);
      border-radius: 0.6rem;
      .using-date {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 400;
      }
      .cost-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & + .cost {
          margin-top: 0.4rem;
        }
        .cost-type {
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 400;
          color: var(--sub-txt-color);
        }
        p {
          font-size: 1.6rem;
          line-height: 2.4rem;
          font-weight: 600;
          span {
            margin-right: 0.2rem;
            font-weight: 700;
          }
        }
      }
      &.large {
        height: 7.6rem;
        border-radius: 0.8rem;
      }
      & + .result-box {
        margin-top: 0.8rem;
      }
    }
  }
  h4 {
    margin: 2.4rem 0 0.8rem;
  }
}
.category-wrap {
  display: flex;
  justify-content: space-between;
  .category {
    flex: 1;
    margin-right: 0.8rem;
    border-top: 1px solid var(--line-color01);
    border-bottom: 1px solid var(--line-color01);
    &:last-child {
      margin-right: 0;
    }
    ul {
      height: 19.6rem;
      padding: 0.8rem;
      padding-right: 0;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #eee;
        border-radius: 10px;
        border: 4px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0);
      }
      li a {
        display: block;
        height: 3rem;
        padding: 0.5rem 0.8rem;
        border-radius: 0.6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--sub-txt-color);
        &.active {
          background-color: #e9eff9;
          color: var(--base-txt-color);
        }
      }
    }
    .head-tt {
      padding: 1.2rem;
      background-color: var(--disabled-bg-color);
      text-align: left;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem;
    }
  }
}
.calendar {
  width: 32rem;
  height: 34.1rem;
  padding: 2.4rem;
  border-radius: 0.8rem;
  background-color: var(--white-color);
  border: 1px solid var(--line-color01);
  box-shadow: 12px 12px 20px -10px #00000014;
  &.year-month {
    height: 24.8rem;
    .date-grid {
      margin-top: 2.4rem;
      grid-row-gap: 2.4rem;
      grid-template-columns: repeat(4, 1fr);
      button {
        width: 5.2rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 500;
      }
    }
  }
  .month-indicator {
    position: relative;
    height: 3.2rem;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 3.2rem;
    color: var(--base-txt-color);
    text-align: center;
    .open-indicator {
      width: 1.6rem;
      height: 1.6rem;
      margin-left: 0.4rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.35214 10.6174C8.96159 11.2515 8.03971 11.2515 7.64917 10.6174L4.82062 6.02438C4.41031 5.35812 4.88964 4.5 5.67211 4.5L11.3292 4.5C12.1117 4.5 12.591 5.35812 12.1807 6.02438L9.35214 10.6174Z' fill='%2342444E'/%3E%3C/svg%3E%0A");
    }
    .move-month {
      position: absolute;
      top: 0;
      width: 3.2rem;
      height: 3.2rem;
      border: 1px solid var(--line-color02);
      border-radius: 0.6rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 7L9.21213 11.7879C9.09497 11.905 9.09497 12.095 9.21213 12.2121L14 17' stroke='%23202020' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        center / 2.4rem no-repeat;
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }
  .day-of-week,
  .date-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }
  .day-of-week {
    margin-top: 2.4rem;
  }
  .day-of-week > * {
    font-size: 1.3rem;
    line-height: 2rem;
    color: var(--sub-txt-color2);
    font-weight: 500;
    text-align: center;
  }
  .date-grid {
    margin-top: 1.7rem;
    grid-row-gap: 1rem;
  }

  .date-grid button {
    position: relative;
    border: 0;
    width: 3.2rem;
    height: 3.2rem;
    margin: 0 auto;
    border-radius: 0.6rem;
    background-color: var(--white-color);
    color: var(--base-txt-color);
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
    &.active {
      background-color: var(--primary-color2);
      color: var(--white-color);
    }
    &.actives {
      width: 100%;
      background-color: var(--primary-color2);
      border-radius: 0;
      color: var(--white-color);
      &.begins {
        border-top-left-radius: 0.6rem;
        border-bottom-left-radius: 0.6rem;
      }
      &.end {
        border-top-right-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
      }
    }
    &.disabled {
      background-color: var(--disabled-bg-color);
      color: var(--primary-color2);
    }
    &.checked {
      border: 1px solid var(--primary-color2);
      color: var(--primary-color2);
    }
    &.out-of-month {
      color: var(--sub-txt-color2);
    }
  }
}
.join-complete {
  margin-top: 4rem;
  padding-bottom: 12rem;
  text-align: center;
  img {
    display: block;
    width: 70rem;
    height: 39.6rem;
    margin: 0 auto;
  }
  .button {
    margin-top: 6rem;
  }
}
.cs-center-wrap {
  // width: 1280px;
  margin: 0 auto;
  padding: 3.2rem 0 6.4rem;
  h2 {
    text-align: center;
    font-size: 3.2rem;
    line-height: 3.2rem;
  }

  .tab-line-menu {
    .tab-list {
      justify-content: center;
    }
  }
  .to-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 3.2rem;
    padding: 2.7rem 4.8rem;
    border: 1px solid var(--line-color02);
    dl {
      display: flex;
    }
    dt {
      margin-right: 2.4rem;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.4rem;
    }
    dd {
      color: var(--sub-txt-color);
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.4rem;
      strong {
        color: var(--base-txt-color);
      }
      span {
        font-weight: 700;
        color: var(--primary-color2);
      }
      p {
        margin-top: 0.2rem;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2rem;
      }
    }
  }
}
.inquire-wrap {
  width: 64rem;
  margin: 4rem auto;
  .inquire-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid var(--line-color03);
    p {
      font-size: 1.7rem;
      font-weight: 500;
      line-height: 2.8rem;
    }
  }
  .form-box {
    margin-top: 2.4rem;
    dt {
      margin-bottom: 0.8rem;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.8rem;
    }
    &.info {
      display: flex;
      justify-content: space-between;
      dl {
        width: 100%;
        margin-right: 0.8rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.service-summary {
  display: flex;
  justify-content: center;
  div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20rem;
    height: 20rem;
    margin-right: 4.4rem;
    padding: 3.2rem 2.4rem 2.4rem;
    background-color: var(--white-color);
    box-shadow: 12px 12px 20px -10px #00000014;
    border-radius: 16px;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.2rem;
      height: 1.2rem;
      right: -2.8rem;
      background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.63804 12V7.36196H0V4.63804H4.63804V0H7.33742V4.63804H12V7.36196H7.33742V12H4.63804Z' fill='%2342444E'/%3E%3C/svg%3E%0A");
    }
    img {
      width: 8.8rem;
      height: 8.8rem;
      margin: 0 auto;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.6rem;
      font-size: 1.8rem;
      line-height: 2.3rem;
      font-weight: 600;
    }
    &:last-child {
      margin-right: 0;
      &:after {
        content: none;
      }
    }
  }
}
.dashboard-contents {
  margin-top: 4rem;
  .user-info {
    display: flex;
    justify-content: space-between;
  }
  .contents-box {
    display: flex;
    width: 100%;
    padding: 3.2rem;
    border: 1px solid var(--line-color02);
    border-radius: 1.2rem;
    box-shadow: 1.2rem 1.2rem 3.2rem 0px #0000000f;
    &.fix-width {
      flex-wrap: wrap;
      width: 1000px;
    }
  }
}
.dashboard-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 12.7rem;
  margin-right: 6.5rem;
  margin-left: 0;
  &.ty2 {
    min-height: auto;
    .box-contents {
      display: block;
      margin-top: 2rem;
    }
    li {
      display: flex;
      justify-content: space-between;
      width: 59.6rem;
      &:last-child {
        margin-top: 0.8rem;
      }
      a {
        font-size: 1.6rem;
        line-height: 2.4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .date {
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--sub-txt-color);
      }
    }
  }
  &.flex2 {
    flex: 2;
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    width: 0.1rem;
    height: 100%;
    right: -3.2rem;
    background-color: var(--line-color02);
  }

  &:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
    &:after {
      content: none;
    }
  }
}
.service-direction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8rem;
  width: 74.4rem;
  .direction-detail {
    flex-shrink: 0;
    position: relative;
    width: 23.2rem;
    height: 34.8rem;
    background-color: var(--white-color);
    border-radius: 8px;
    box-shadow: 12px 12px 20px -10px #00000014;
    text-align: center;
    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 50%;
      transform: translateY(-50%);
      width: 10rem;
      height: 6.9rem;
      background: url("data:image/svg+xml,%0A%3Csvg width='235' height='165' viewBox='0 0 235 165' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M235 82.5L176.175 31.4209V47.2793H124.673C112.206 47.2793 49.4236 44.9705 37.3367 39.7224C30.9918 36.9593 24.619 33.2967 18.6637 28.3082C15.4727 25.6285 12.291 22.476 9.33186 18.6558C6.01098 14.372 2.29122 8.81793 0 0V165C2.29122 156.191 6.01098 150.628 9.33186 146.344C12.291 142.533 15.4727 139.381 18.6637 136.701C24.619 131.713 30.9918 128.05 37.3367 125.287C49.4236 120.039 112.206 117.73 124.673 117.73H176.175V133.588L235 82.5093V82.5Z' fill='url(%23paint0_linear_1912_64440)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1912_64440' x1='235.771' y1='82.5' x2='59.2633' y2='82.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2392B4FB'/%3E%3Cstop offset='1' stop-color='white'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")
        center / 10rem 6.9rem no-repeat;
      opacity: 50%;
    }
    &:first-child::after {
      left: calc(100% - 5.2rem);
    }
    &:last-child::after {
      transform: rotate(180deg) translateY(50%);
      right: calc(100% - 5.2rem);
    }
    .detail-head {
      height: 5.6rem;
      font-size: 2rem;
      font-weight: 700;
      line-height: 5.6rem;
      color: var(--white-color);
      background-color: var(--bg-color-green);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    ul {
      padding: 1.6rem;
      li {
        margin-bottom: 0.4rem;
        padding: 0.8rem 0;
        font-size: 1.6rem;
        line-height: 2.4rem;
        font-weight: 700;
        color: var(--sub-txt-color);
        background-color: var(--disabled-bg-color);
        border-radius: 0.4rem;
        &:last-child {
          margin-bottom: 0;
        }
        &.ty2 {
          padding: 1.9rem 0;
        }
      }
    }
  }
  .direction-core {
    position: relative;
    z-index: -2;
    width: 25.615rem;
    height: 25.615rem;
    background-color: #2b91f0;
    border-radius: 50%;
    color: var(--white-color);
    text-align: center;
    h3 {
      margin-top: 5rem;
      margin-bottom: 1.6rem;
      font-size: 2.2rem;
      line-height: 2.6rem;
      color: var(--white-color);
    }
    ul {
      li {
        display: flex;
        justify-content: center;
        margin-bottom: 0.8rem;
        font-size: 1.8rem;
        line-height: 2.1rem;
        font-weight: 600;
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 2rem;
          height: 2rem;
          margin-right: 0.4rem;
          background: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' fill='white'/%3E%3Cpath d='M15.0777 6C15.3135 6 15.5493 6.08987 15.7296 6.27014C16.0901 6.63067 16.0901 7.21347 15.7296 7.574L8.75827 14.5453C8.58492 14.7187 8.35069 14.8155 8.10634 14.8155C7.86199 14.8155 7.62684 14.7187 7.45441 14.5453L4.2704 11.3613C3.90987 11.0008 3.90987 10.418 4.2704 10.0575C4.63092 9.69693 5.21373 9.69693 5.57426 10.0575L8.10634 12.5895L14.4257 6.27014C14.606 6.08987 14.8419 6 15.0777 6Z' fill='%23055DB5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.10634 12.5895L5.57426 10.0575C5.21373 9.69693 4.63092 9.69693 4.2704 10.0575C3.90987 10.418 3.90987 11.0008 4.2704 11.3613L7.45441 14.5453C7.62684 14.7187 7.86199 14.8155 8.10634 14.8155C8.35069 14.8155 8.58492 14.7187 8.75827 14.5453L15.7296 7.574C16.0901 7.21347 16.0901 6.63067 15.7296 6.27014C15.5493 6.08987 15.3135 6 15.0777 6C14.8419 6 14.606 6.08987 14.4257 6.27014L8.10634 12.5895Z' fill='%23055DB5'/%3E%3C/svg%3E%0A")
            bottom center / 100% no-repeat;
        }
      }
    }
    &:before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #2b91f0;
      border-radius: 50%;
      opacity: 20%;
    }
    &:before {
      z-index: -1;
      width: 31rem;
      height: 31rem;
    }
    &:after {
      z-index: -2;
      width: 36.4rem;
      height: 36.4rem;
    }
  }
}
.service-txt {
  margin-top: 5.6rem;
  text-align: center;
  p {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.4rem;
    & + p {
      font-weight: 400;
    }
  }
}
.greeting {
  width: 43.2rem;
  margin-left: 10.4rem;
  .greeting-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.1rem;
  }
  .greeting-tit {
    font-weight: 700;
    h3 {
      font-size: 2.4rem;
      line-height: 3rem;
      & + p {
        margin-top: 1.6rem;
        font-size: 1.4rem;
        span {
          color: var(--primary-color2);
          font-size: 1.8rem;
        }
      }
    }
  }
  .greeting-img {
    width: 15.5rem;
    height: 18.1rem;
    background-color: var(--disabled-bg-color);
    border-radius: 1.6rem;
  }
  .greeting-txt {
    p {
      margin-bottom: 0.8rem;
      color: var(--strong-txt-color);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.box-tit {
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  .dash-tit {
    font-size: 2rem;
    & + span {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.4rem;
      color: var(--sub-txt-color);
    }
  }
}
.box-bottom {
  margin-top: auto;
  text-align: right;
  dl {
    display: flex;
    justify-content: space-between;
    & + dl {
      margin-top: 0.8rem;
    }
  }
  .num {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    &.large {
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 3.1rem;
      span {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
    span {
      margin-left: 0.2rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      color: var(--sub-txt-color);
    }
  }
  .dl-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3.2rem;
    margin-top: 0.4rem;
  }
  dl.ty2 {
    width: 12.7rem;
    margin-top: 0;
    margin-left: 0;
    align-items: center;
    &:last-child dd::after {
      content: none;
    }
    dt,
    dd {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    dd {
      position: relative;
      font-weight: 600;
      color: var(--strong-txt-color);
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% + 1.6rem);
        transform: translateY(-50%);
        height: 1.6rem;
        width: 0.1rem;
        background-color: var(--bg-color-blue02);
      }
    }
  }
  dt {
    font-weight: 500;
    color: var(--sub-txt-color);
  }
}
.pie {
  border-radius: 50%;
  &.chart {
    width: 14rem;
    height: 14rem;
    background-image: conic-gradient(
      var(--primary-color2) 45%,
      var(--bg-color-green) 45%,
      var(--bg-color-green) 70%,
      #fac858 70%,
      #fac858 85%,
      #ee6666 85%,
      #ee6666 95%,
      #73c0de 95%,
      #73c0de 100%
    );
  }
  &.account {
    position: relative;
    width: 16rem;
    height: 16rem;
    margin: 1.2rem auto 0;
    background-image: conic-gradient(#5470c6 55%, #91cc75 45%);
    &::before {
      content: '';
      position: absolute;
      width: 12rem;
      height: 12rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--white-color);
      border-radius: 50%;
    }
    .pie-detail {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      > p {
        margin-bottom: 0.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        color: var(--sub-txt-color);
      }
      h4 {
        font-size: 2rem;
        line-height: 3rem;
        & + p {
          font-size: 1.2rem;
          font-weight: 400;
          line-height: 1.8rem;
        }
      }
    }
  }
}
.box-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // height: 17.6rem;
  margin-top: 1.2rem;
  .chart-index {
    width: 16rem;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem;
      color: var(--sub-txt-color);
      > p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
      }
      .color-circle {
        width: 1.2rem;
        height: 1.2rem;
        margin-top: -0.2rem;
        margin-right: 0.8rem;
        border-radius: 50%;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        text-align: right;
        font-weight: 400;
      }
      &:nth-child(1) {
        .color-circle {
          background-color: var(--primary-color2);
        }
      }
      &:nth-child(2) {
        .color-circle {
          background-color: var(--bg-color-green);
        }
      }
      &:nth-child(3) {
        .color-circle {
          background: #fac858;
        }
      }
      &:nth-child(4) {
        .color-circle {
          background: #ee6666;
        }
      }
      &:nth-child(5) {
        .color-circle {
          background: #73c0de;
        }
      }
    }
  }
}
.contents-box {
  padding: 16rem 0;
  &.contents-1 {
    position: relative;
    height: 80rem;
    padding-top: 12rem;
    background: url(/images/common/fo-main-1.png) bottom center / 1920px no-repeat;
    text-align: center;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(248, 250, 255, 0) 0%, #ecf3ff 100%);
    }
    h2 {
      margin-bottom: 0;
      font-size: 5rem;
      line-height: 6rem;
      & + h2 {
        margin-top: 0.4rem;
        margin-bottom: 1.6rem;
      }
    }
    p {
      font-size: 2.2rem;
      line-height: 3.2rem;
      & + p {
        margin-top: 0.4rem;
      }
    }
  }
  &.contents-2,
  &.contents-3 {
    padding-top: 20rem;
    padding-bottom: 0;
  }
  &.contents-4,
  &.contents-5 {
    h2 {
      margin-bottom: 1.6rem;
    }
  }
  h2 {
    margin-bottom: 6.4rem;
    font-size: 4.4rem;
    line-height: 5.3rem;
    text-align: center;
  }
  .features {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 8rem;
    &:last-child {
      margin-bottom: 0;
    }
    .num-count {
      width: 4rem;
      height: 4rem;
      background-color: var(--primary-color2);
      border-radius: 50%;
      color: var(--white-color);
      text-align: center;
      font-size: 1.4;
      font-weight: 700;
      line-height: 4rem;
    }
    h3 {
      margin-top: 0.8rem;
      margin-bottom: 2rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
      span {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 2.6rem;
        color: var(--sub-txt-color);
      }
    }
    p {
      margin-bottom: 1.2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: var(--strong-txt-color);
      &:last-child {
        margin-bottom: 0;
      }
    }
    .example {
      display: inline-block;
      padding: 0.4rem 0.6rem;
      background-color: #f8faff;
      border: 1px solid var(--line-color03);
      border-radius: 0.4rem;
      font-size: 1.4rem;
      line-height: 2rem;
      color: var(--sub-txt-color);
      &:first-child {
        margin-bottom: 0.3rem;
      }
    }
  }
  .features-txt {
    flex-shrink: 0;
    width: 40rem;
    margin-right: 4.8rem;
  }
  .features-img {
    position: relative;
    flex-shrink: 0;
    width: 83.2rem;
    height: 41.6rem;
    border-radius: 1.2rem;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 1.2rem;
      border: 1px solid var(--line-color03);
    }
    img {
      width: calc(100% + 2px);
    }
  }
  &.contents-4 {
    background: linear-gradient(0deg, #f8faff 0%, #f7faff 0.01%, rgba(248, 250, 255, 0) 100%);
    .erp-summary {
      display: flex;
      justify-content: center;
      margin: 1.6rem 0;
      span {
        margin-right: 0.8rem;
        padding: 0.4rem 1.2rem;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
        color: var(--primary-color2);
        background-color: #e9eff9;
        border-radius: 4px;
        &:last-child {
          margin-right: 0;
        }
      }
      & + p {
        text-align: center;
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
    .erp-detail {
      display: flex;
      justify-content: space-between;
      width: 1280px;
      margin: 8rem auto 0;

      .card {
        position: relative;
        width: 30.5rem;
        height: 40.8rem;
      }
      .card-side {
        height: 100%;
        padding: 3.2rem;
        transition: all 0.8s ease;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        backface-visibility: hidden;
        overflow: hidden;
        background-color: #e7f1fd;
        background-position: bottom center;
        background-repeat: no-repeat;
        border-radius: 1.6rem;
        p {
          margin-top: 1.2rem;
          text-align: left;
          & + p {
            margin-top: 0.8rem;
          }
        }
        &:last-child {
          p {
            word-break: break-all;
          }
        }
      }

      .card-side-front-1 {
        background-image: url(/images/common/erp-detail1.png);
      }
      .card-side-front-2 {
        background-image: url(/images/common/erp-detail2.png);
      }
      .card-side-front-3 {
        background-image: url(/images/common/erp-detail3.png);
      }
      .card-side-front-4 {
        background-image: url(/images/common/erp-detail4.png);
      }
      .card-side-back {
        transform: rotateY(180deg);
        background: var(--white-color);
        border: 1px solid var(--line-color02);
      }

      .card:hover .card-side-front-1,
      .card:hover .card-side-front-2,
      .card:hover .card-side-front-3,
      .card:hover .card-side-front-4 {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
      }

      .card:hover .card-side-back {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
      }

      .card-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .card-heading {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 3.2rem;
        text-align: left;
        & + .view-more {
          width: 4.2rem;
          height: 4.2rem;
          margin-right: 0;
          background: var(--strong-txt-color)
            url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12H12M12 12H18M12 12V6M12 12V18' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
            center center / 2.4rem 2.4rem no-repeat;
          border-radius: 50%;
        }
      }
    }
  }
  &.contents-5 {
    color: var(--white-color);
    background: url(/images/common/charge-bg.png) center / cover no-repeat;
    text-align: center;
    h2,
    h2 + p,
    h4 {
      color: var(--white-color);
    }
    .vat {
      width: 1040px;
      margin: 2.5rem auto 0.8rem;
      padding-right: 1.6rem;
      text-align: right;
      font-size: 1.4rem;
      line-height: 2.3rem;
      opacity: 0.6;
    }
    .charge-wrap {
      display: flex;
      justify-content: center;
      .charge-detail {
        // width: 33.6rem;
        width: 22rem;
        height: 114.4rem;
        margin-right: 1rem;
        padding: 5.6rem 0;
        background-color: rgba($color: #ffffff, $alpha: 0.1);
        border: 1px solid rgba($color: #eeeeee, $alpha: 0.2);
        border-radius: 1.6rem;
        &:last-child {
          margin-right: 0;
        }
        h4 {
          margin-bottom: 3.2rem;
          font-size: 2.2rem;
          line-height: 3.2rem;
          & + p {
            font-size: 1.6rem;
            line-height: 1.6rem;
            font-weight: 500;
            color: #14b9ff;
          }
        }
        .month-charge {
          margin: 1.2rem 0 2.4rem;
          font-size: 4rem;
          line-height: 3.2rem;
          font-weight: 500;
          span {
            font-size: 1.8rem;
            line-height: 3.2rem;
          }
        }
        ul li {
          opacity: 0.7;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 5.2rem;
        }
        .addition-service {
          margin-bottom: 1.6rem;
          padding: 1.2rem 0;
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 1.6rem;
          background-color: rgba($color: #ffffff, $alpha: 0.05);
        }
      }
      & + p {
        margin-top: 2.4rem;
        font-size: 1.4rem;
        line-height: 2.3rem;
        font-weight: 400;
        opacity: 0.6;
      }
    }
  }
  &.contents-6 {
    opacity: 1;
    margin-top: 0;
    padding: 11.4rem 0;
    background: url(/images/common/contact-bg.png) center / cover no-repeat;
    .contact-us {
      width: 1280px;
      margin: 0 auto;
      color: var(--white-color);
      p {
        margin-bottom: 0.8rem;
        font-weight: 1.8rem;
        line-height: 2.8rem;
        font-weight: 700;
        text-align: left;
        opacity: 0.7;
      }
      .flex {
        justify-content: space-between;
        h3 {
          font-size: 4rem;
          line-height: 5.6rem;
          color: var(--white-color);
        }
        .btn-wrap {
          display: flex;
          align-items: center;
          .button {
            width: 22.4rem;
            height: 5.6rem;
            padding: 0;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 5.6rem;
            text-align: center;
          }
        }
      }
    }
  }
}

.mo {
  .button.mini.round {
    font-size: 1.2rem;
    line-height: 1.8rem;
    background-color: var(--disabled-bg-color);
    border: 1px solid var(--line-color02);
    color: var(--sub-txt-color);
  }
  .tab-line-menu {
    border-bottom: 0;
    .tab-inner {
      padding: 0;
      margin-left: -1.6rem;
      margin-right: -1.6rem;
      .tab-list {
        display: flex;
        .tab {
          justify-content: center;
          flex-shrink: 0;
          flex: 1;
          height: 4.8rem;
          padding: 0;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 2rem;
          color: var(--sub-txt-color);
          box-sizing: border-box;
          border-width: 0;
          > a {
            height: 4.8rem;
            padding: 0;
            line-height: 4.8rem;
            background-color: #e9eff9;
            border-bottom: 3px solid #e9eff9;
            &::after {
              content: none;
            }
          }
          &.active,
          &:hover {
            background-color: #e9eff9;
          }
          &.active a {
            border: none;
            border-bottom: 3px solid var(--primary-color2);
            color: var(--primary-color2);
          }
          &.tab:hover a,
          &.tab.active a {
            line-height: 4.8rem;
          }
        }
      }
    }
  }
  .tab-panel {
    padding-top: 2.4rem;
    padding-bottom: 6.4rem;
  }
  .contents {
    dl {
      margin-bottom: 1.6rem;
    }
    dt {
      margin-bottom: 0.8rem;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 2rem;
    }
    dd {
      .select-box + .input-box,
      .select-box + .select-box,
      .select-box + .text-area {
        // margin-top: 0.4rem;
        margin-left: 0;
      }
    }
  }

  .btn-wrap {
    display: flex;
    .button {
      flex: 1;
      text-align: center;
    }
  }
  .h-line {
    height: 1.6rem;
    margin: 3.2rem -1.6rem;
    background-color: var(--disabled-bg-color);
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    &.ty2 {
      margin-bottom: 0;
    }
  }
  .select-box {
    background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9L12 15L6 9' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    &.disabled {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 9L12 15L6 9' stroke='%23CCCCCC' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
  .select-box.mini {
    background: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 4.5L6 7.5L3 4.5' stroke='%23666666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
      center right 1rem / 1.2rem no-repeat;
    .init {
      padding-right: 0;
    }
    button {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }
  .page_wrap {
    margin-top: 3.2rem;
    .arrow {
      width: 3.2rem;
    }
  }
  .td-notice {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  .load-screen {
    left: -1.6rem;
    right: -1.6rem;
    .load-contents .load-spinner {
      margin-top: 1.6rem;
    }
  }
  .collect-index {
    position: relative;
    min-height: 23.6rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--strong-txt-color);
    .collect-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 5.2rem;
      margin: 1.6rem -1.6rem 0;
      padding: 0 1.6rem;
      border-top: 1px solid var(--line-color02);
      border-bottom: 1px solid var(--line-color02);
      font-weight: 700;
      background-color: #f8faff;
    }
    ul li {
      display: flex;
      justify-content: space-between;
      line-height: 4.4rem;
      border-bottom: 1px solid var(--line-color02);
      p {
        font-weight: 600;
      }
    }
  }
  .panel {
    position: fixed;

    left: 50%;
    transform: translateX(-50%);
    .panel-contents {
      width: 100%;
      height: 100%;
      padding: 1.6rem;
    }
    .panel-head {
      margin-bottom: 2.4rem;
      padding-top: 0.8rem;
      h2 {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.4rem;
      }
    }
    .panel-bottm {
      position: relative;
      right: auto;
      bottom: auto;
      margin-top: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
  .table {
    margin: 0 -1.6rem;
    .radio-box {
      line-height: 1;
    }
    table th,
    table td {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      &.small-pd {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
      }
    }
    table th {
      font-size: 1.5rem;
      &.middle-pd {
        padding-top: 1rem;
        padding-bottom: 1rem;
        p {
          font-size: 1.3rem;
          line-height: 2rem;
          color: var(--strong-txt-color);
        }
      }
    }
  }
  .btn-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.8rem;
    color: var(--sub-txt-color);
    div {
      a {
        position: relative;
        margin-right: 0.6rem;
        padding-right: 0.8rem;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 1.2rem;
          background-color: var(--bg-color-blue02);
        }
        &:last-child {
          margin-right: 0;
          padding-right: 0;
          &::after {
            content: none;
          }
        }
      }
    }
  }
  .check-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.has-bd,
    &.has-bg {
      margin: 0 -1.6rem;
      padding: 1.2rem 1.6rem;
    }
    &.has-bd {
      flex-wrap: wrap;
      border-top: 1px solid var(--line-color02);
      border-bottom: 1px solid var(--line-color02);
    }
    &.has-bg {
      background-color: #f8faff;
      border: none;
    }
  }
  .check-box.sm-check + .sm-check {
    margin-left: 2.1rem;
  }
  .check-box .checkbox + label {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .product-list {
    &.ty2 {
      li:last-child {
        border-bottom: 0;
      }
      .has-claim {
        font-size: 1.3rem;
        line-height: 2rem;
      }
      .check-box {
        p {
          font-weight: 600;
        }
      }
      .a-link2 {
        display: -webkit-box;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: keep-all;
      }
      dl {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        padding: 1.2rem 0;
        border-top: 1px solid var(--line-color02);
        dt {
          margin-bottom: 0;
          padding-right: 1.6rem;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
        dd {
          font-size: 1.4rem;
          line-height: 2rem;
        }
        &.block-dl {
          display: block;
          border-bottom: 1px solid var(--line-color02);
          dt {
            margin-bottom: 0.8rem;
          }
          & + .block-dl {
            border-top-width: 0;
          }
        }
        &.small-pd {
          padding: 0.6rem 0;
        }
      }
    }
    &.inquiry {
      li {
        padding: 0 1.6rem;
        dl {
          &:first-child {
            border-top-width: 0;
          }
          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
    li {
      margin: 0 -1.6rem;
      padding: 1.6rem;
      border-bottom: 1px solid var(--line-color02);
      > div {
        & + dl {
          margin-top: 1.6rem;
        }
      }
    }
  }
  .product-detail {
    margin-top: 0.6rem;
    padding-left: 3.2rem;
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .product-code {
    color: var(--sub-txt-color);
    & + p {
      margin-bottom: 0.8rem;
    }
  }
  .search-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -2.4rem -1.6rem 2.4rem;
    padding: 0 1.6rem;
    height: 4.8rem;
    background-color: var(--disabled-bg-color);
    border-bottom: 1px solid var(--line-color02);
    font-weight: 600;
    p {
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
    div {
      font-size: 1.3rem;
    }
    &.ty2 {
      margin: 3.2rem -1.6rem 2.4rem;
      border-top: 1px solid var(--line-color02);
    }
  }
  .popup {
    width: 32.8rem;
    padding: 2.4rem 1.6rem;
    h2 {
      font-size: 1.6rem;
    }
    .close {
      margin-right: 0;
    }
    .pop-contents {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2rem;
      word-break: break-all;
      .table {
        margin: 0 auto;
      }
    }
  }
  .has-tit {
    .pop-bottm {
      margin-top: 1.6rem;
      &.center {
        .button {
          width: 16rem;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }
  .progress-box {
    margin: 0;
    padding: 4rem 0;
    border: none;
  }
}
.exposure-list {
  li {
    margin: 0 -1.6rem;
    padding: 1.6rem;

    border-bottom: 1px solid var(--line-color02);
    &:last-child {
      border-bottom-width: 0;
    }
  }
  .exposure-unit {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    img {
      width: 15.2rem;
      height: 15.2rem;
    }
  }
  .unit-define {
    width: 16rem;
    display: flex;
    flex-direction: column;
    .check-box {
      margin-left: 0;
    }
    > * {
      margin-bottom: 0.8rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .unit-detail {
    padding: 1.2rem 0;
    border-top: 1px solid var(--line-color02);
    color: var(--strong-txt-color);
    font-size: 1.4rem;
    &.send-market {
      margin-bottom: 0.6rem;
      border-bottom: 1px solid var(--line-color02);
    }
    &.name {
      a {
        display: block;
        position: relative;
        padding-right: 2.4rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        font-weight: 600;
      }
      i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.cost {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;
      gap: 3.3rem;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 1.4rem;
        background-color: var(--line-color02);
      }
      dl {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        dt {
          margin-bottom: 0;
        }
      }
    }
    &.send-market {
      font-weight: 600;
      i {
        margin-right: 0;
      }
      .ft-list {
        display: flex;
        justify-content: space-between;
        .open-list {
          display: block;
        }
        .close-list {
          display: none;
        }
      }
    }
    .ft-list.open {
      & + .market-index {
        display: block;
      }
      .close-list {
        display: block;
      }
      .open-list {
        display: none;
      }
    }
    .market-index {
      display: none;
      padding-top: 1.2rem;
      .flex {
        gap: 2.4rem;
        > div {
          flex: 1;
        }
      }
      dl {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.contents {
  .log-data {
    padding-bottom: 6.4rem;
    li {
      border-top: 1px solid var(--line-color01);
      border-bottom: 1px solid var(--line-color01);
      & + li {
        margin-top: 1.6rem;
      }
    }
    .log-date {
      padding: 1.2rem 0;
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 2rem;
      text-align: center;
      background-color: var(--disabled-bg-color);
    }
    dl {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 1.2rem 0;
      border-top: 1px solid var(--line-color02);
      &.block-dl {
        display: block;
        dd {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
    }
    dt {
      min-width: 8rem;
    }
    .a-link2 {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
.mo-guide-tt {
  font-size: 2.8rem;
  line-height: 2.4rem;
  font-weight: 700;
  margin: 3.2rem 0;
}
.mo-sub-tt {
  font-size: 2.4rem;
  font-weight: 600;
  margin: 2.4rem 0 1.6rem;
}
.contents {
  .dashboard-mo {
    margin-top: 1.2rem;
    padding: 2rem 1.6rem;
    background-color: var(--white-color);
    border-radius: 1.2rem;
    border: 1px solid var(--line-color02);
    ul li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--line-color02);
      font-size: 1.4rem;
      line-height: 2.4rem;
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
      p {
        font-weight: 500;
        & + div {
          span {
            font-weight: 600;
          }
        }
      }
    }
    .board-index {
      li {
        display: block;
        a {
          display: block;
          font-weight: 500;
        }
        .date {
          margin-top: 0.4rem;
          color: var(--sub-txt-color);
          font-size: 1.3rem;
          line-height: 2rem;
        }
      }
    }

    dl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 -1.6rem 1.6rem;
      padding: 0 1.6rem 1.6rem;
      border-bottom: 1px solid var(--line-color02);
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }
      dt {
        margin-bottom: 0;
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2rem;
      }
      dd {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: var(--strong-txt-color);
        span {
          margin-right: 0.2rem;
          font-size: 2.2rem;
          font-weight: 600;
          line-height: 2.6rem;
          color: var(--base-txt-color);
        }
      }
    }
    .box-contents {
      justify-content: center;
      margin-top: 0;
      .chart-index {
        width: 14.4rem;
        margin-right: 2.4rem;
        li {
          margin-bottom: 0.8rem;
          padding-bottom: 0;
          border-bottom: 0;
          line-height: 2rem;

          p {
            font-size: 1.4rem;
            font-weight: 600;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .pie.chart {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}

.terms-contents {
  
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
}